import React, { useState } from "react";
import factacy from "../../assets/Factacy-grayLogomark (1).png";
import inventor from "../../assets/Factacy INVENTOR badge.svg";
import investor from "../../assets/Factacy Investor Badge.svg";
import unicorn from "../../assets/Factacy Unicorn Badge.svg";
import funded from "../../assets/Factacy Funded company Badge.svg";
import { goToCompanyPage } from "../../constant/function";

const CompetitorGroot = ({
  brandId,
  competitorDetails,
  company,
  companyId,
}) => {

  const [clickedCompanyId, setClickedCompanyId] = useState(
    company.Brand
  );

// useAddMetaTags(company?.Brand ?? company?.Company_Name, "Competitors, Alternatives", company?.Keywords, company?.Comp_Description)


  return (
    <div className=" bg-white sm:w-full rounded-b-xl flex flex-col lg:p-8 sm:p-4 gap-6">
      <div className="w-full flex flex-col-reverse lg:flex-row sm:gap-12 lg:gap-6">
        <table className="table-auto lg:w-1/2">
          <thead>
            <tr className="text-start">
              {/* <th></th>
              <th></th>
              <th></th> */}
              {/* <th className="text-start">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9788 18.4704C6.37637 18.4704 2.6454 14.7394 2.6454 10.137C2.6454 5.53467 6.37637 1.80371 10.9788 1.80371C15.5811 1.80371 19.3121 5.53467 19.3121 10.137C19.3121 14.7394 15.5811 18.4704 10.9788 18.4704ZM10.9788 16.8037C14.6607 16.8037 17.6454 13.8189 17.6454 10.137C17.6454 6.45514 14.6607 3.47038 10.9788 3.47038C7.29683 3.47038 4.31207 6.45514 4.31207 10.137C4.31207 13.8189 7.29683 16.8037 10.9788 16.8037ZM8.06207 11.8037H12.6454C12.8755 11.8037 13.0621 11.6172 13.0621 11.387C13.0621 11.1569 12.8755 10.9704 12.6454 10.9704H9.31208C8.16148 10.9704 7.22873 10.0376 7.22873 8.88702C7.22873 7.73645 8.16148 6.80371 9.31208 6.80371H10.1454V5.13704H11.8121V6.80371H13.8954V8.47035H9.31208C9.08195 8.47035 8.8954 8.65694 8.8954 8.88702C8.8954 9.11719 9.08195 9.30369 9.31208 9.30369H12.6454C13.796 9.30369 14.7288 10.2364 14.7288 11.387C14.7288 12.5376 13.796 13.4704 12.6454 13.4704H11.8121V15.137H10.1454V13.4704H8.06207V11.8037Z"
                    fill="#FAE243"
                  />
                  <path
                    d="M10.9788 18.4694C6.3764 18.4694 2.64543 14.7385 2.64543 10.136C2.64543 5.53369 6.3764 1.80273 10.9788 1.80273C15.5811 1.80273 19.3121 5.53369 19.3121 10.136C19.3121 14.7385 15.5811 18.4694 10.9788 18.4694ZM10.9788 16.8027C14.6607 16.8027 17.6454 13.818 17.6454 10.136C17.6454 6.45417 14.6607 3.4694 10.9788 3.4694C7.29686 3.4694 4.3121 6.45417 4.3121 10.136C4.3121 13.818 7.29686 16.8027 10.9788 16.8027Z"
                    fill="#FAE243"
                  />
                </svg>
              </th>
              <th className="">
                {" "}
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2821 14.6046L10.9787 19.9079L5.67544 14.6046C2.74651 11.6756 2.74651 6.9269 5.67544 3.99797C8.60438 1.06904 13.3531 1.06904 16.2821 3.99797C19.211 6.9269 19.211 11.6756 16.2821 14.6046ZM10.9787 12.6346C12.8197 12.6346 14.3121 11.1422 14.3121 9.30127C14.3121 7.46032 12.8197 5.96794 10.9787 5.96794C9.13779 5.96794 7.64541 7.46032 7.64541 9.30127C7.64541 11.1422 9.13779 12.6346 10.9787 12.6346ZM10.9787 10.9679C10.0582 10.9679 9.31208 10.2218 9.31208 9.30127C9.31208 8.38079 10.0582 7.6346 10.9787 7.6346C11.8992 7.6346 12.6454 8.38079 12.6454 9.30127C12.6454 10.2218 11.8992 10.9679 10.9787 10.9679Z"
                    fill="#3E91EE"
                  />
                  <path
                    d="M16.2821 14.6041L10.9787 19.9074L5.67544 14.6041C2.74651 11.6751 2.74651 6.92641 5.67544 3.99748C8.60438 1.06855 13.3531 1.06855 16.2821 3.99748C19.211 6.92641 19.211 11.6751 16.2821 14.6041ZM10.9787 12.6341C12.8197 12.6341 14.3121 11.1417 14.3121 9.30078C14.3121 7.45983 12.8197 5.96745 10.9787 5.96745C9.13779 5.96745 7.64541 7.45983 7.64541 9.30078C7.64541 11.1417 9.13779 12.6341 10.9787 12.6341Z"
                    fill="#3E91EE"
                  />
                </svg>
              </th> */}
            </tr>
          </thead>
          <tbody>
            <tr
              className={`text-start rounded-lg hover:bg-gray-100 cursor-pointer ${
                clickedCompanyId == `${company.Brand}`
                  ? "bg-[#E8F5FF] rounded-lg"
                  : ""
              }`}
              onClick={() => {
                setClickedCompanyId(company.Brand);
              }}
            >
              <td className="w-1/12">
                <img
                  src={company.Logo ?? factacy}
                  className="w-16 h-16 object-contain self-center "
                />
              </td>
              <td className="w-1/4 text-start px-3">
                <div className="flex w-full items-center justify-start gap-2">
                  <p className="uppercase">
                    {company.Brand ?? company.Brand_Name}
                  </p>
                </div>
              </td>
              <td className="w-1/4 text-center">
                {company.Badges && (
                  <div className="flex gap-0 items-center">
                    {company.Badges.includes("Unicorn") && (
                      <div className="flex items-center">
                        <img
                          src={unicorn}
                          className="w-6 h-6 cursor-pointer drop-shadow-lg"
                          title="Unicorn"
                        />
                      </div>
                    )}
                    {company.Badges.includes("Inventor") && (
                      <div className="flex items-center">
                        <img
                          src={inventor}
                          className="w-6 h-6 cursor-pointer drop-shadow-lg"
                          title="Inventor"
                        />
                      </div>
                    )}
                    {company.Badges.includes("Investor") && (
                      <div className="flex items-center">
                        <img
                          src={investor}
                          className="w-7 h-7 cursor-pointer drop-shadow-lg"
                          title="Investor"
                        />
                      </div>
                    )}
                    {company.Badges.includes("Funded") && (
                      <div className="flex items-center">
                        <img
                          src={funded}
                          className="w-7 h-7 cursor-pointer drop-shadow-lg"
                          title="Funded Company"
                        />
                      </div>
                    )}
                  </div>
                )}
              </td>
              {/* <td className="w-1/4">
                {company.Amount && Array.isArray(company?.Series)
                  ? `(${company?.Amount}) 
                    ${company?.Series[0]}`
                  : "Unconfirmed"}
              </td>
              <td className="w-1/4">{company.State}</td> */}
            </tr>

            {competitorDetails?.map((item, i) => (
              <tr
                key={i}
                className={`text-start rounded-lg hover:bg-gray-100 cursor-pointer ${
                  clickedCompanyId == `${item.Brand}`
                    ? "bg-[#E8F5FF] rounded-lg"
                    : ""
                }`}
                onClick={() => {
                  setClickedCompanyId(item.Brand);
                }}
              >
                <td className="w-1/12">
                  <img
                    src={item.Logo ?? factacy}
                    className="w-16 h-16 object-contain  "
                  />
                </td>

                <td className="w-1/4 text-start px-3">
                  <div className="flex w-full items-center justify-start gap-2">
                    <p className="uppercase">
                      {item.Brand ?? item.Brand}
                    </p>
                  </div>
                </td>

                <td className="w-1/4">
                  {item.Badges && (
                    <div className="flex gap-0 items-center">
                      {item.Badges.includes("Unicorn") && (
                        <div className="flex items-center">
                          <img
                            src={unicorn}
                            className="w-6 h-6 cursor-pointer drop-shadow-lg"
                            title="Unicorn"
                          />
                        </div>
                      )}
                      {item.Badges.includes("Inventor") && (
                        <div className="flex items-center">
                          <img
                            src={inventor}
                            className="w-6 h-6 cursor-pointer drop-shadow-lg"
                            title="Inventor"
                          />
                        </div>
                      )}
                      {item.Badges.includes("Investor") && (
                        <div className="flex items-center">
                          <img
                            src={investor}
                            className="w-7 h-7 cursor-pointer drop-shadow-lg"
                            title="Investor"
                          />
                        </div>
                      )}
                      {item.Badges.includes("Funded") && (
                        <div className="flex items-center">
                          <img
                            src={funded}
                            className="w-7 h-7 cursor-pointer drop-shadow-lg"
                            title="Funded Company"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </td>

                {/* <td className="w-1/4">
                  {item.Amount && Array.isArray(item?.Series)
                    ? `(${item?.Amount}) 
                    ${item?.Series[0]}`
                    : "Unconfirmed"}
                </td>

                <td className="w-1/4">{item.State}</td> */}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="lg:w-1/2">
          {clickedCompanyId === company.Brand && (
            <div className="flex flex-col gap-3 w-full">
              <div className="w-full">
                <div className="flex items-center gap-3">
                  <img
                    className="w-16 h-16 object-contain"
                    src={company.Logo ?? factacy}
                  ></img>
                  <div
                    className="font-medium flex gap-2 items-center text-2xl hover:underline hover:text-primaryBlue cursor-pointer"
                    onClick={() =>
                      goToCompanyPage(companyId, [company.Company_Name], brandId, [company.Brand])
                    }
                  >
                    <p className="w-full uppercase">
                      {company.Brand ?? company.Brand}
                    </p>

                    {company.Badges && (
                      <div className="flex gap-0 items-center">
                        {company.Badges.includes("Unicorn") && (
                          <div className="flex items-center">
                            <img
                              src={unicorn}
                              className="w-6 h-6 cursor-pointer drop-shadow-lg"
                              title="Unicorn"
                            />
                          </div>
                        )}
                        {company.Badges.includes("Inventor") && (
                          <div className="flex items-center">
                            <img
                              src={inventor}
                              className="w-6 h-6 cursor-pointer drop-shadow-lg"
                              title="Inventor"
                            />
                          </div>
                        )}
                        {company.Badges.includes("Investor") && (
                          <div className="flex items-center">
                            <img
                              src={investor}
                              className="w-7 h-7 cursor-pointer drop-shadow-lg"
                              title="Investor"
                            />
                          </div>
                        )}
                        {company.Badges.includes("Funded") && (
                          <div className="flex items-center">
                            <img
                              src={funded}
                              className="w-7 h-7 cursor-pointer drop-shadow-lg"
                              title="Funded companyany"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full gap-4">
                <p className="text-[#71717A] max-w-full">{company.Summary}</p>
                {/* <div className="flex w-full">
                  <div className="flex flex-col w-1/2 gap-4">
                    <div className="flex gap-2">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.2821 14.6046L10.9787 19.9079L5.67544 14.6046C2.74651 11.6756 2.74651 6.9269 5.67544 3.99797C8.60438 1.06904 13.3531 1.06904 16.2821 3.99797C19.211 6.9269 19.211 11.6756 16.2821 14.6046ZM10.9787 12.6346C12.8197 12.6346 14.3121 11.1422 14.3121 9.30127C14.3121 7.46032 12.8197 5.96794 10.9787 5.96794C9.13779 5.96794 7.64541 7.46032 7.64541 9.30127C7.64541 11.1422 9.13779 12.6346 10.9787 12.6346ZM10.9787 10.9679C10.0582 10.9679 9.31208 10.2218 9.31208 9.30127C9.31208 8.38079 10.0582 7.6346 10.9787 7.6346C11.8992 7.6346 12.6454 8.38079 12.6454 9.30127C12.6454 10.2218 11.8992 10.9679 10.9787 10.9679Z"
                          fill="#3E91EE"
                        />
                        <path
                          d="M16.2821 14.6041L10.9787 19.9074L5.67544 14.6041C2.74651 11.6751 2.74651 6.92641 5.67544 3.99748C8.60438 1.06855 13.3531 1.06855 16.2821 3.99748C19.211 6.92641 19.211 11.6751 16.2821 14.6041ZM10.9787 12.6341C12.8197 12.6341 14.3121 11.1417 14.3121 9.30078C14.3121 7.45983 12.8197 5.96745 10.9787 5.96745C9.13779 5.96745 7.64541 7.45983 7.64541 9.30078C7.64541 11.1417 9.13779 12.6341 10.9787 12.6341Z"
                          fill="#3E91EE"
                        />
                      </svg>
                      <p className="font-roboto">{company.State}</p>
                    </div>
                    <div className="flex gap-2">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9788 18.4704C6.37637 18.4704 2.6454 14.7394 2.6454 10.137C2.6454 5.53467 6.37637 1.80371 10.9788 1.80371C15.5811 1.80371 19.3121 5.53467 19.3121 10.137C19.3121 14.7394 15.5811 18.4704 10.9788 18.4704ZM10.9788 16.8037C14.6607 16.8037 17.6454 13.8189 17.6454 10.137C17.6454 6.45514 14.6607 3.47038 10.9788 3.47038C7.29683 3.47038 4.31207 6.45514 4.31207 10.137C4.31207 13.8189 7.29683 16.8037 10.9788 16.8037ZM8.06207 11.8037H12.6454C12.8755 11.8037 13.0621 11.6172 13.0621 11.387C13.0621 11.1569 12.8755 10.9704 12.6454 10.9704H9.31208C8.16148 10.9704 7.22873 10.0376 7.22873 8.88702C7.22873 7.73645 8.16148 6.80371 9.31208 6.80371H10.1454V5.13704H11.8121V6.80371H13.8954V8.47035H9.31208C9.08195 8.47035 8.8954 8.65694 8.8954 8.88702C8.8954 9.11719 9.08195 9.30369 9.31208 9.30369H12.6454C13.796 9.30369 14.7288 10.2364 14.7288 11.387C14.7288 12.5376 13.796 13.4704 12.6454 13.4704H11.8121V15.137H10.1454V13.4704H8.06207V11.8037Z"
                          fill="#FAE243"
                        />
                        <path
                          d="M10.9788 18.4694C6.3764 18.4694 2.64543 14.7385 2.64543 10.136C2.64543 5.53369 6.3764 1.80273 10.9788 1.80273C15.5811 1.80273 19.3121 5.53369 19.3121 10.136C19.3121 14.7385 15.5811 18.4694 10.9788 18.4694ZM10.9788 16.8027C14.6607 16.8027 17.6454 13.818 17.6454 10.136C17.6454 6.45417 14.6607 3.4694 10.9788 3.4694C7.29686 3.4694 4.3121 6.45417 4.3121 10.136C4.3121 13.818 7.29686 16.8027 10.9788 16.8027Z"
                          fill="#FAE243"
                        />
                      </svg>
                      <p className="font-roboto">
                        {company?.Amount && Array.isArray(company?.Series)
                          ? `(${company?.Amount}) 
                    ${company?.Series[0]}`
                          : "Unconfirmed"}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 gap-4">
                    <div className="flex gap-2">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.47876 10.1346H6.81209V17.6346H3.47876V10.1346ZM15.1454 6.80127H18.4788V17.6346H15.1454V6.80127ZM9.31209 1.80127H12.6454V17.6346H9.31209V1.80127Z"
                          fill="#B5EEC3"
                        />
                        <path
                          d="M3.47876 10.1341H6.81209V17.6341H3.47876V10.1341ZM15.1454 6.80078H18.4788V17.6341H15.1454V6.80078Z"
                          fill="#B5EEC3"
                        />
                      </svg>
                      <p className="font-roboto">unconfirmed</p>
                    </div>
                    <div className="flex gap-2">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_6_1116)">
                          <path
                            d="M2.23563 18.4678C2.23563 14.7859 5.22039 11.8011 8.90229 11.8011C12.5842 11.8011 15.569 14.7859 15.569 18.4678H2.23563ZM8.90229 10.9678C6.13979 10.9678 3.90229 8.73027 3.90229 5.96777C3.90229 3.20527 6.13979 0.967773 8.90229 0.967773C11.6648 0.967773 13.9023 3.20527 13.9023 5.96777C13.9023 8.73027 11.6648 10.9678 8.90229 10.9678ZM15.038 12.8288C17.6091 13.4859 19.5422 15.7374 19.722 18.4678H17.2356C17.2356 16.2929 16.4025 14.3127 15.038 12.8288ZM13.3524 10.9319C14.713 9.71127 15.569 7.9395 15.569 5.96777C15.569 4.78666 15.2618 3.67728 14.723 2.71517C16.6317 3.09608 18.069 4.77998 18.069 6.80111C18.069 9.10319 16.2044 10.9678 13.9023 10.9678C13.7159 10.9678 13.5323 10.9555 13.3524 10.9319Z"
                            fill="#FF7F7F"
                          />
                          <path
                            d="M2.23557 18.4678C2.23557 14.7859 5.22033 11.8011 8.90223 11.8011C12.5841 11.8011 15.5689 14.7859 15.5689 18.4678H2.23557ZM8.90223 10.9678C6.13973 10.9678 3.90223 8.73027 3.90223 5.96777C3.90223 3.20527 6.13973 0.967773 8.90223 0.967773C11.6647 0.967773 13.9022 3.20527 13.9022 5.96777C13.9022 8.73027 11.6647 10.9678 8.90223 10.9678Z"
                            fill="#FF7F7F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6_1116">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0.97876 0.134277)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className="font-roboto">unconfirmed</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          )}
          {competitorDetails?.map((comp, index) => (
            <div key={index}>
              {clickedCompanyId === comp.Brand && (
                <div className="flex flex-col gap-3 w-full">
                  <div className="w-full">
                    <div className="flex items-center gap-3">
                      <img
                        className="w-16 h-16 object-contain"
                        src={comp.Logo}
                      ></img>
                      <div
                        className="font-medium flex gap-2 items-center text-2xl hover:underline hover:text-primaryBlue cursor-pointer"
                        onClick={() =>
                          goToCompanyPage(comp.Reference_Id, [comp.Brand], comp.id, [comp.Brand])
                        }
                      >
                        <p className="w-full uppercase">
                          {comp.Brand ?? comp.Brand}
                        </p>

                        {comp.Badges && (
                          <div className="flex gap-0 items-center">
                            {comp.Badges.includes("Unicorn") && (
                              <div className="flex items-center">
                                <img
                                  src={unicorn}
                                  className="w-6 h-6 cursor-pointer drop-shadow-lg"
                                  title="Unicorn"
                                />
                              </div>
                            )}
                            {comp.Badges.includes("Inventor") && (
                              <div className="flex items-center">
                                <img
                                  src={inventor}
                                  className="w-6 h-6 cursor-pointer drop-shadow-lg"
                                  title="Inventor"
                                />
                              </div>
                            )}
                            {comp.Badges.includes("Investor") && (
                              <div className="flex items-center">
                                <img
                                  src={investor}
                                  className="w-7 h-7 cursor-pointer drop-shadow-lg"
                                  title="Investor"
                                />
                              </div>
                            )}
                            {comp.Badges.includes("Funded") && (
                              <div className="flex items-center">
                                <img
                                  src={funded}
                                  className="w-7 h-7 cursor-pointer drop-shadow-lg"
                                  title="Funded Company"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-4">
                    <p className="text-[#71717A] max-w-full">{comp.Summary}</p>
                    {/* <div className="flex w-full">
                      <div className="flex flex-col w-1/2 gap-4">
                        <div className="flex gap-2">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.2821 14.6046L10.9787 19.9079L5.67544 14.6046C2.74651 11.6756 2.74651 6.9269 5.67544 3.99797C8.60438 1.06904 13.3531 1.06904 16.2821 3.99797C19.211 6.9269 19.211 11.6756 16.2821 14.6046ZM10.9787 12.6346C12.8197 12.6346 14.3121 11.1422 14.3121 9.30127C14.3121 7.46032 12.8197 5.96794 10.9787 5.96794C9.13779 5.96794 7.64541 7.46032 7.64541 9.30127C7.64541 11.1422 9.13779 12.6346 10.9787 12.6346ZM10.9787 10.9679C10.0582 10.9679 9.31208 10.2218 9.31208 9.30127C9.31208 8.38079 10.0582 7.6346 10.9787 7.6346C11.8992 7.6346 12.6454 8.38079 12.6454 9.30127C12.6454 10.2218 11.8992 10.9679 10.9787 10.9679Z"
                              fill="#3E91EE"
                            />
                            <path
                              d="M16.2821 14.6041L10.9787 19.9074L5.67544 14.6041C2.74651 11.6751 2.74651 6.92641 5.67544 3.99748C8.60438 1.06855 13.3531 1.06855 16.2821 3.99748C19.211 6.92641 19.211 11.6751 16.2821 14.6041ZM10.9787 12.6341C12.8197 12.6341 14.3121 11.1417 14.3121 9.30078C14.3121 7.45983 12.8197 5.96745 10.9787 5.96745C9.13779 5.96745 7.64541 7.45983 7.64541 9.30078C7.64541 11.1417 9.13779 12.6341 10.9787 12.6341Z"
                              fill="#3E91EE"
                            />
                          </svg>
                          <p className="font-roboto">{comp.State}</p>
                        </div>
                        <div className="flex gap-2">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.9788 18.4704C6.37637 18.4704 2.6454 14.7394 2.6454 10.137C2.6454 5.53467 6.37637 1.80371 10.9788 1.80371C15.5811 1.80371 19.3121 5.53467 19.3121 10.137C19.3121 14.7394 15.5811 18.4704 10.9788 18.4704ZM10.9788 16.8037C14.6607 16.8037 17.6454 13.8189 17.6454 10.137C17.6454 6.45514 14.6607 3.47038 10.9788 3.47038C7.29683 3.47038 4.31207 6.45514 4.31207 10.137C4.31207 13.8189 7.29683 16.8037 10.9788 16.8037ZM8.06207 11.8037H12.6454C12.8755 11.8037 13.0621 11.6172 13.0621 11.387C13.0621 11.1569 12.8755 10.9704 12.6454 10.9704H9.31208C8.16148 10.9704 7.22873 10.0376 7.22873 8.88702C7.22873 7.73645 8.16148 6.80371 9.31208 6.80371H10.1454V5.13704H11.8121V6.80371H13.8954V8.47035H9.31208C9.08195 8.47035 8.8954 8.65694 8.8954 8.88702C8.8954 9.11719 9.08195 9.30369 9.31208 9.30369H12.6454C13.796 9.30369 14.7288 10.2364 14.7288 11.387C14.7288 12.5376 13.796 13.4704 12.6454 13.4704H11.8121V15.137H10.1454V13.4704H8.06207V11.8037Z"
                              fill="#FAE243"
                            />
                            <path
                              d="M10.9788 18.4694C6.3764 18.4694 2.64543 14.7385 2.64543 10.136C2.64543 5.53369 6.3764 1.80273 10.9788 1.80273C15.5811 1.80273 19.3121 5.53369 19.3121 10.136C19.3121 14.7385 15.5811 18.4694 10.9788 18.4694ZM10.9788 16.8027C14.6607 16.8027 17.6454 13.818 17.6454 10.136C17.6454 6.45417 14.6607 3.4694 10.9788 3.4694C7.29686 3.4694 4.3121 6.45417 4.3121 10.136C4.3121 13.818 7.29686 16.8027 10.9788 16.8027Z"
                              fill="#FAE243"
                            />
                          </svg>
                          <p className="font-roboto">
                            {comp.Amount && Array.isArray(comp?.Series)
                              ? `(${comp?.Amount}) 
                    ${comp?.Series[0]}`
                              : "Unconfirmed"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2 gap-4">
                        <div className="flex gap-2">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.47876 10.1346H6.81209V17.6346H3.47876V10.1346ZM15.1454 6.80127H18.4788V17.6346H15.1454V6.80127ZM9.31209 1.80127H12.6454V17.6346H9.31209V1.80127Z"
                              fill="#B5EEC3"
                            />
                            <path
                              d="M3.47876 10.1341H6.81209V17.6341H3.47876V10.1341ZM15.1454 6.80078H18.4788V17.6341H15.1454V6.80078Z"
                              fill="#B5EEC3"
                            />
                          </svg>
                          <p className="font-roboto">unconfirmed</p>
                        </div>
                        <div className="flex gap-2">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_6_1116)">
                              <path
                                d="M2.23563 18.4678C2.23563 14.7859 5.22039 11.8011 8.90229 11.8011C12.5842 11.8011 15.569 14.7859 15.569 18.4678H2.23563ZM8.90229 10.9678C6.13979 10.9678 3.90229 8.73027 3.90229 5.96777C3.90229 3.20527 6.13979 0.967773 8.90229 0.967773C11.6648 0.967773 13.9023 3.20527 13.9023 5.96777C13.9023 8.73027 11.6648 10.9678 8.90229 10.9678ZM15.038 12.8288C17.6091 13.4859 19.5422 15.7374 19.722 18.4678H17.2356C17.2356 16.2929 16.4025 14.3127 15.038 12.8288ZM13.3524 10.9319C14.713 9.71127 15.569 7.9395 15.569 5.96777C15.569 4.78666 15.2618 3.67728 14.723 2.71517C16.6317 3.09608 18.069 4.77998 18.069 6.80111C18.069 9.10319 16.2044 10.9678 13.9023 10.9678C13.7159 10.9678 13.5323 10.9555 13.3524 10.9319Z"
                                fill="#FF7F7F"
                              />
                              <path
                                d="M2.23557 18.4678C2.23557 14.7859 5.22033 11.8011 8.90223 11.8011C12.5841 11.8011 15.5689 14.7859 15.5689 18.4678H2.23557ZM8.90223 10.9678C6.13973 10.9678 3.90223 8.73027 3.90223 5.96777C3.90223 3.20527 6.13973 0.967773 8.90223 0.967773C11.6647 0.967773 13.9022 3.20527 13.9022 5.96777C13.9022 8.73027 11.6647 10.9678 8.90223 10.9678Z"
                                fill="#FF7F7F"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6_1116">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0.97876 0.134277)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <p className="font-roboto">unconfirmed</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* <div className="flex gap-2 items-center">
        <p className="font-semibold text-xl">Other Competitors:</p>
        <div className="flex pt-1">
          {competitors &&
            Object.entries(competitors)
              .filter(([key, value]) => value === null)
              .map(([key], index, array) => (
                <div key={index} className="flex">
                  <p className="">
                    {" "}
                    {"  "} {key}
                    {index !== array.length - 1 && ", "}
                  </p>
                </div>
              ))}
        </div>
      </div> */}
    </div>
  );
};

export default CompetitorGroot;




