import React, { useState } from "react";
import Deals from "./deals";
import DealsDash from "./dealsDash";
import SideBarLayoutDeals from "./sideBarLayoutDeals";
import { Route, Routes } from "react-router-dom";
import HistorialDeals from "./historialDeals";
import AltDealsPage from "./AltDealsPage";
import Sidebar from "../sideBarMain";
import OpenDeals from "../../pages/OpenDeals";
import AltDeals from "../../pages/AltDeals";
import Errorpage from "../errorpage";
import UnderConstruction from "../underConstruction";
import Reports from "../Reports/Reports";
import MetaTagesAdder from "../MetaTagesAdder";
import SingleDealPage from "./singleDealPage";
import InvestmentShifts from '../investorIntelligence/investmentShifts'
import AllDeals from "./AllDeals";

const DealIntelligence = () => {
  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
      <Routes>
        {/* <Route element={<>Hello</>} path="/" /> */}
        <Route element={<MetaTagesAdder>
          <DealsDash />
        </MetaTagesAdder>} path="/deals-dashboard" />
        <Route element={<MetaTagesAdder><AllDeals /></MetaTagesAdder>} path="/dealsx" />
        <Route element={<MetaTagesAdder><Deals /></MetaTagesAdder>} path="/deals" />
        <Route element={<MetaTagesAdder><HistorialDeals /></MetaTagesAdder>} path="/historical-deals" />
        <Route element={<MetaTagesAdder><OpenDeals /></MetaTagesAdder>} path="/open-deals" />
        <Route element={<MetaTagesAdder><AltDeals /></MetaTagesAdder>} path="/alt-deals" />
        <Route element={<MetaTagesAdder><AltDealsPage /></MetaTagesAdder>} path="/alt-deals/:referenceId" />
        <Route element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path="/exits" />
        <Route element={<MetaTagesAdder>< InvestmentShifts /></MetaTagesAdder>} path="/investment-trends-themes" />
        <Route path="/deal/:id/:investee-raises-/:round-round-with-/:investor" element={<SingleDealPage />} />
        <Route path="/reports/:reportFrontendId" element={<Reports />} />
        <Route path="*" element={<Errorpage />} />
      </Routes>
    </div>
  );
  // return (
  //   <div className="lg:w-full flex flex-col">
  //     <div className="bg-gradient-to-b w-full flex h-screen">
  //       {/* <SideBarLayoutDeals /> */}
  //       {/* <Sidebar /> */}
  //       <div className="w-full overflow-y-auto">
  //         <div className="lg:h-20 sm:h-5"></div>
  //         <Routes>
  //           <Route element={<MetaTagesAdder>
  //             <DealsDash />
  //           </MetaTagesAdder>} path="/deals-dashboard" />
  //           <Route element={<MetaTagesAdder><Deals /></MetaTagesAdder>} path="/deals" />
  //           <Route element={<MetaTagesAdder><HistorialDeals /></MetaTagesAdder>} path="/historical-deals" />
  //           <Route element={<MetaTagesAdder><OpenDeals /></MetaTagesAdder>} path="/open-deals" />
  //           <Route element={<MetaTagesAdder><AltDeals /></MetaTagesAdder>} path="/alt-deals" />
  //           <Route element={<MetaTagesAdder><AltDealsPage /></MetaTagesAdder>} path="/alt-deals/:referenceId" />
  //           <Route element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path="/exits" />
  //           <Route element={<MetaTagesAdder>< InvestmentShifts/></MetaTagesAdder>} path="/investment-trends-themes" />
  //           <Route path="/deal/:id/:investee-raises-/:round-round-with-/:investor" element={<SingleDealPage />} />
  //           <Route path="/reports/:reportFrontendId" element={<Reports />} />
  //           <Route path="*" element={<Errorpage />} />
  //         </Routes>
  //       </div>
  //     </div>
  //   </div>
  // );
};
export default DealIntelligence;