import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatWithGrootRenderd, setIsChatWithGrootRenderd] = useState(false);
  const [showChatWithGroot, setShowChatWithGroot] = useState(false);
  const navigate = useNavigate()

  const openChat = () => {

    if (!sessionStorage.getItem('email')) {
      alert('Please login first');
      navigate('/login')

    }
    else {
      if (!isChatWithGrootRenderd) {
        setIsChatWithGrootRenderd(true);
      }
      setShowChatWithGroot(true);
    }

  }
  const closeChat = () => setShowChatWithGroot(false);

  return (
    <ChatContext.Provider value={{ isChatOpen, openChat, closeChat, isChatWithGrootRenderd, setIsChatWithGrootRenderd, showChatWithGroot, setShowChatWithGroot }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);

