// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const StageWiseActivity = () => {
//   const [stage, setStage] = useState([]);

//   useEffect(() => {
//     const fetchSectors = async () => {
//       const response = await axios.get(
//         "https://factacyinsights.in/seed_information"
//       );

//       setStage(response.data);
//     };
//     fetchSectors();
//   }, []);

//   const handleStageClick = async (stage) => {
//     if (stage !== undefined && stage !== "NUll") {
//       window.location.href = `/investor-intelligence/investor-activity-stagewise/${stage}`;
//     }
//   };


//   return (
//     <div  className="mx-4 my-2 bg-white rounded-lg p-6 flex flex-col gap-4">
//        <p className="text-xl font-semibold">Funding Rounds</p>
//     <div className="flex flex-wrap gap-8">

//       {stage &&
//         stage.map((stage, index) => (
//           <div key={index} onClick={() => handleStageClick(stage.Corrected_Series)} className="flex gap-1 w-60 text-lg justify-center shadow-sm font-semibold bg-white py-2 px-3 items-center rounded-md cursor-pointer border-[1px] hover:bg-white hover:scale-105 duration-200 hover:shadow-md">
//             <p>{(stage.Corrected_Series).toUpperCase()}</p>
//           </div>
//         ))}
//     </div>
//     </div>
//   );
// };

// export default StageWiseActivity;


import React, { useEffect, useState } from "react";
import axios from "axios";
import StagePage from "./stagePage";
import arrow from '../../assets/sidebar assets/arrow.svg';

const stage_mapping = {
  'Seed Stage': ['SEED PLUS', 'SEED 2', 'SEED 3', 'LATE-SEED', 'PRE SEED', 'SEED', 'SEED EXTENSION', 'POST-SEED', 'PRE-SEED', 'ANGEL', 'CROWDFUNDING', 'PRE-SEED-A'],
  'Early Stage': ['PRE-SERIES-A', 'PRE-SERIES-A1', 'A', 'A EXTENSION', 'A1', 'A2', 'A3', 'A+', 'PRE-SERIES-B'],
  'Later Stage': ['B2', 'C1', 'C2', 'D EXTENSION', 'C', 'C EXTENSION', 'G', 'C3', 'B', 'D', 'D2', 'PRE-SERIES-C', 'E', 'B3', 'B EXTENSION', 'D1', 'G EXTENSION', 'PRE-SERIES-D', 'B1', 'B+', 'G2', 'F', 'B3 EXTENSION', 'G1', 'H', 'I', 'J']
}
const StageWiseActivity = () => {
  const [stage, setStage] = useState([]);
  const [activeStage, setActiveStage] = useState("SEED PLUS");
  const [activeHeading, setActiveHeading] = useState(null);

  // useEffect(() => {
  //   const fetchSectors = async () => {
  //     const response = await axios.get(
  //       "https://factacyinsights.in/seed_information"
  //     );

  //     setStage(response.data);
  //   };
  //   fetchSectors();
  // }, []);

  // const handleStageClick = async (stage) => {
  //   if (stage !== undefined && stage !== "NUll") {
  //     window.location.href = `/investor-intelligence/investor-activity-stagewise/${stage}`;
  //   }
  // };


  return (
    <div className="mx-4 my-0 relative rounded-xl overflow-hidden bg-[#FAFAFA] flex flex-col lg:flex-row">
      <div className="w-full lg:w-[12rem] h-[calc(100vh-6rem)] py-2 flex flex-row lg:flex-col overflow-x-scroll">
        {
          Object.keys(stage_mapping)?.map((item, index) => (
            <div key={index} >
              <div 
              onClick={(e) => { setActiveHeading(pre => pre === item ? null : item) }}
              className=" flex justify-between items-center cursor-pointer"
              >
                <p className=" text-xl pl-4 font-roboto sm:text-lg font-bold leading-9">{item}</p>
                <img
                  className={`w-6 ${activeHeading === item && ' rotate-180'}`}
                  src={arrow}
                  alt="arrow Icon"
                />
              </div>
              <div className={` flex flex-row lg:flex-col overflow-hidden ${activeHeading === item ? ' h-full' : ' h-0'}`}>
                {stage_mapping[item]?.map((item, index) =>
                (<span
                  onClick={() => setActiveStage(item)}
                  className={`px-4 lg:pl-6 py-3 w-full lg:text-sm font-roboto hover:bg-white cursor-pointer ${activeStage === item ? " bg-white text-primaryBlue" : "text-[#2d2d2d]"}`}
                  key={index}>
                  {item}
                </span>))}
              </div>
            </div>))
        }
      </div>
      {/* <div className="w-full lg:w-[12rem] py-2 flex flex-row lg:flex-col overflow-x-scroll">
        {
          Object.keys(stage_mapping)?.map((item, index) => (
            <div key={index}>
              <p className=" text-xl pl-4 font-roboto sm:text-lg font-bold leading-9">{item}</p>
              <div className=" flex flex-row lg:flex-col">
                {stage_mapping[item]?.map((item, index) =>
                (<span
                  onClick={() => setActiveStage(item)}
                  className={`px-4 lg:pl-6 py-3 w-max lg:text-sm font-roboto hover:bg-white cursor-pointer ${activeStage === item ? " bg-white text-primaryBlue" : "text-[#2d2d2d]"}`}
                  key={index}>
                  {item}
                </span>))}
              </div>
            </div>))
        }
      </div> */}
      <div className=" flex-1 bg-white">
        <StagePage stage={activeStage} />
      </div>
    </div>
  );
};

export default StageWiseActivity;