import React from 'react'

const Membership = () => {
        return (
            <div className={`section  flex flex-col gap-4 w-full`}>
                <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-6' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                    <div className='flex justify-between items-center'>
                        <p className='text-xl font-bold'>Membership Plan</p>
                        <p className='text-sm text-primaryBlue underline'>Manage Plan</p>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-6 lg:gap-0'>
                        <div className='flex flex-col lg:w-1/4'>
                            <p className='text-sm text-gray-400'>Plan</p>
                            <p>single user</p>
                        </div>
                        <div className='flex flex-col lg:w-1/4'>
                            <p className='text-sm text-gray-400'>Billing Cycle</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/4'>
                            <p className='text-sm text-gray-400'>Price</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/4'>
                            <p className='text-sm text-gray-400'>Current Billing Period</p>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                    <p className='text-xl font-bold'>Payment Method</p>
                    <p className='text-base text-gray-600'>Your charges will be deducted from the default card shown below. This can be changed by adding a new card and making it the default using the menu on the right.</p>
                    <div className='flex lg:flex-row flex-col gap-6 lg:gap-0 bg-gray-100 p-2'>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Details</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Brand</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Type</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Last four digits</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Expiration</p>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                    <p className='text-xl font-bold'>Invoices</p>
                    <p className='text-base text-gray-600'>Your charges will be deducted from the default card shown below. This can be changed by adding a new card and making it the default using the menu on the right.</p>
                    <div className='flex lg:flex-row flex-col gap-6 lg:gap-0 bg-gray-100 p-2'>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Plan</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Price / Frequency</p>
                            <p></p>
                        </div>
                        <div className='flex flex-col lg:w-1/5'>
                            <p className='text-sm text-gray-500'>Billing period</p>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className='h-max w-full bg-white rounded-lg p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                    <p className='text-xl font-bold'>Cancel membership</p>
                    <p className='text-base text-gray-600'>To cancel your Factacy membership, please note that your access to the product will be revoked after the end of your current billing cycle.</p>
                    <div className='px-4 py-3 text-white bg-[#ff7f7e] w-max rounded-full '>Cancel membership</div>
                </div>
    
            </div>
        )
    }

export default Membership
