import React from 'react'

const Logout = () => {
    return (
        <div className={`section `}>logout</div>

    )
}

export default Logout
