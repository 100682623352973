import React, { useEffect } from "react";
import user from "../../assets/account-circle-fill.svg";
import useProfile from "../../hooks/Profile/useProfile";
import Loader from "../tearSheet/loader";

const Home = () => {
  const { loading, creditHistory, getCreditHistory } = useProfile();

  useEffect(() => {
    getCreditHistory();
  }, []);


  return (
    <div className={`section  flex lg:flex-row flex-col gap-4 w-full`}>
      <div className="flex flex-col gap-4 w-full lg:w-1/3">
        <div
          className="bg-white rounded-lg w-full h-max gap-8 p-6 flex flex-col"
          style={{
            boxShadow:
              "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"
          }}
        >
          <div className="flex items-center gap-3">
            <img className="w-20 h-20" src={user} />
            <div className="flex flex-col gap-1">
              <p className="text-xl font-semibold">
                {creditHistory[0]?.first_name +
                  " " +
                  creditHistory[0]?.last_name}
              </p>
              <p className="text-xs px-3 py-1 text-primaryBlue rounded-full w-max bg-[#E8F5FF]">
                Premium - Single User
              </p>
            </div>
          </div>
          {/* <div></div> */}
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Email Address</p>
            <p>{sessionStorage.getItem("email") ?? "-"}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Contact Number</p>
            <p>{creditHistory[0]?.phone ?? "-"}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Location/Time Zone</p>
            <p>{Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Member Since</p>
            <p>{creditHistory[0]?.date_of_regist ?? "-"}</p>
          </div>
        </div>
        <div
          className="bg-white rounded-lg w-full h-max gap-8 p-6 flex flex-col"
          style={{
            boxShadow:
              "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"
          }}
        >
          <p className="text-xl font-bold">Billing Details</p>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Plan</p>
            <p>-</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Billing Cycle</p>
            <p>-</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Price</p>
            <p>-</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Current Billing Period</p>
            <p>-</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full lg:w-2/3">
        <div
          className="bg-white rounded-lg w-full h-[500px] overflow-y-auto p-6"
          style={{
            boxShadow:
              "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"
          }}
        >
          <div className="flex justify-between items-start">
            <p className="text-2xl lg:text-3xl font-bold">Credit History</p>
            <div className="px-3 py-3 border-2 rounded-full text-xs font-semibold">
              Credits : {creditHistory[0]?.token ?? "-"}
            </div>
          </div>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th className="text-start">Site</th>
                    <th className="text-start">Tokens</th>
                    <th className="text-start">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {creditHistory[0]
                    ? creditHistory[0]?.sitemap
                        ?.filter((item) => item.token > 0)
                        .map((item) => (
                          <tr
                            className="text-xs lg:text-base font-light text-gray-600"
                            key={item.epoch}
                          >
                            <td className="overflow-x-auto w-1/2 text-primaryBlue cursor-pointer py-3 " onClick={() => window.open(item.site)}>

                              {item.pathname.length > 1 && item.pathname[1] !== "Null"
                                ? item.pathname[0]+" : "+item.pathname[1] : item.pathname.length > 1 && item.pathname[1] === "Null" ? item.pathname[0]+" : "+item.pathname[3]
                                : item.pathname[0]
                                
                                }
                            </td>
                            <td>{item.token}</td>
                            <td>{item.date}</td>
                          </tr>
                        ))
                    : "No sites found."}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="bg-white rounded-lg w-full h-max p-6 lg:p-8 flex lg:flex-row gap-3 lg:gap-0 flex-col justify-between items-start lg:items-center"
          style={{
            boxShadow:
              "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"
          }}
        >
          <p className="lg:w-2/3 font-bold text-xl lg:text-2xl">
            See guides to get started with Factacy now
          </p>
          <div className="border-2 border-black px-4 py-2 text-sm rounded-full w-max h-max">
            See User Manual
          </div>
        </div>
        <div
          className="bg-white rounded-lg w-full h-max p-6 lg:p-8 flex lg:flex-row flex-col justify-between items-start lg:gap-0 gap-4 lg:items-center"
          style={{
            boxShadow:
              "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"
          }}
        >
          <div className="flex flex-col gap-1">
            <p className="text-sm text-gray-400">
              Share referral code to earn credits
            </p>
            <p className="text-2xl font-bold">FACT189</p>
          </div>
          <div className="border-2 border-black px-4 py-2 text-sm rounded-full w-max h-max">
            Share Referral Code
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
