import React, { useEffect, useState } from "react";
import deal from "../../assets/sidebar assets/deal.svg";
import news from "../../assets/sidebar assets/news.svg";
import investor from "../../assets/sidebar assets/investor.svg";
import person from "../../assets/sidebar assets/person.svg";
import community from "../../assets/sidebar assets/community.svg";
import sector from "../../assets/sidebar assets/sector.svg";
import innovations from "../../assets/sidebar assets/innovations.svg";
import company from "../../assets/sidebar assets/company.svg";
import search from "../../assets/sidebar assets/search.svg";
import arrow from "../../assets/sidebar assets/arrow.svg";
import home from "../../assets/sidebar assets/home.svg";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import openCloseArrow from "../../assets/Icons/arrow_right_alt.svg";
const Sidebar = () => {
  // const Link = useNavigate()
  const data = [
    {
      // heading: "Home",
      subHeadings: [
        {
          text: "Home",
          active: "my-feed",
          path: "/home/my-feed",
          image: home,
        },
      ],
    },
    {
      heading: "Search",
      image: search,
      subHeadings: [
        {
          text: "Discover Company",
          active: "company-search",
          path: "/company-intelligence/company-search",
        },
        {
          text: "Discover People",
          active: "person-search",
          path: "/people-intelligence/person-search",
        },

        {
          text: "Discover Investor",
          active: "investor-search",
          path: "/investor-intelligence/investor-search",
        },
        {
          text: "Discover Sector",
          active: "sector-search",
          path: "/sector-intelligence/sector-search",
        },

        {
          text: "Discover Patent",
          active: "patent-search",
          path: "/patent-intelligence/patent-search",
        },
      ],
    },
    {
      heading: "Deal Intelligence",
      image: deal,
      subHeadings: [
        {
          text: "Deal Dashboard",
          active: "deals-dashboard",
          path: "/deal-intelligence/deals-dashboard",
        },
        {
          text: "Latest Deals",
          active: "deals",
          path: "/deal-intelligence/deals",
        },
        {
          text: "Historical Deals",
          active: "historical-deals",
          path: "/deal-intelligence/historical-deals",
        },
        {
          text: "Open deals",
          active: "open-deals",
          path: "/deal-intelligence/open-deals",
        },
        {
          text: "ALT Deals",
          active: "alt-deals",
          path: "/deal-intelligence/alt-deals",
        },
        // {
        //   text: "Exits",
        //   active: "/",
        //   path: "/deal-intelligence/exits",
        // },
        {
          text: "Investment trends and themes",
          active: "investment-trends-themes",
          path: "/deal-intelligence/investment-trends-themes",
        },
        {
          text: "Journal",
          path: "/deal-intelligence/reports/latest-every-day",
          active: "reports",
        },
      ],
    },
    {
      heading: "Company Intelligence",
      image: company,
      subHeadings: [
        {
          text: "Company Dashboard",
          active: "company-dashboard",
          path: "/company-intelligence/company-dashboard",
        },
        {
          text: "Company Watch",
          active: "company-watch",
          path: "/company-intelligence/company-watch",
        },
        {
          text: "Competitor watch",
          active: "competitor-watch",
          path: "/company-intelligence/competitor-watch",
        },
      ],
    },
    {
      heading: "Investor Intelligence",
      image: investor,
      subHeadings: [
        {
          text: "Investor Dashboard",
          active: "investor-dashboard",
          path: "/investor-intelligence/investor-dashboard",
        },
        // {
        //   text: "Investor Watch",
        //   active: "investor",
        //   path: "/investor-intelligence/investor-watch",
        // },

        {
          text: "Sector wise Investor Activity",
          active: "investor-activity-sectorwise",
          path: "/investor-intelligence/investor-activity-sectorwise",
        },
        {
          text: "Stage wise Investor Activity",
          active: "investor-activity-stagewise",
          path: "/investor-intelligence/investor-activity-stagewise",
        },
        {
          text: "Investor Leaderboard",
          active: "investor-leaderboard",
          path: "/investor-intelligence/investor-leaderboard",
        },
        {
          text: "Investment shifts and trends",
          active: "investment-shifts-trends",
          path: "/investor-intelligence/investment-shifts-trends",
        },
        {
          text: "ALT Portfolio",
          active: "alt-portfolio",
          path: "/investor-intelligence/alt-portfolio",
        },
        {
          text: "Potential investors",
          active: "potential-investors",
          path: "/investor-intelligence/potential-investors",
        },
      ],
    },
    {
      heading: "Sector Intelligence",
      image: sector,
      subHeadings: [
        {
          text: "Sector Dashboard",
          active: "sector-dashboard",
          path: "/sector-intelligence/sector-dashboard",
        },
        // {
        //   text: "Sector Watch",
        //   active: "sector",
        //   path: "/sector-intelligence/sector",
        // },
        // {
        //   text: "Investors and SMEs",
        //   active: "/",
        //   path: "/sector-intelligence/investors-smes",
        // },
        // {
        //   text: "Relevant companies",
        //   active: "/",
        //   path: "/sector-intelligence/relevent-companies",
        // },
        // {
        //   text: "Sector Innovations",
        //   active: "/",
        //   path: "/sector-intelligence/sector-innovations",
        // },
      ],
    },
    {
      heading: "Innovations",
      image: innovations,
      subHeadings: [
        {
          text: "Search Patents",
          active: "patent-search",
          path: "/patent-intelligence/patent-search",
        },
        {
          text: "Patent Dashboard",
          active: "patent-dashboard",
          path: "/patent-intelligence/patent-dashboard",
        },
        // {
        //   text: "Patents by Industry",
        //   active: "patent-search-by-industry",
        //   path: "/patent-intelligence/patent-search-by-industry",
        // },
        // {
        //   text: "Patents by Company",
        //   active: "patent-search-by-company",
        //   path: "/patent-intelligence/patent-search-by-company",
        // },
        // {
        //   text: "Patents by Investor",
        //   active: "patent-search-by-investor",
        //   path: "/patent-intelligence/patent-search-by-investor",
        // },
      ],
    },
    {
      heading: "People Intelligence",
      image: person,
      subHeadings: [
        // {
        //   text: "Cohorts, Alumni and connections",
        //   active: "/",
        //   path: "/people-intelligence/cohorts-alumni-connections",
        // },
        // {
        //   text: "Person Watch",
        //   active: "person",
        //   path: "/people-intelligence/person-watch",
        // },
        // {
        //   text: "Contacts",
        //   active: "/",
        //   path: "/people-intelligence/contacts",
        // },
        // {
        //   text: "Inventors and Innovators",
        //   active: "/",
        //   path: "/people-intelligence/inventors-innovators",
        // },
        {
          text: "Key People",
          active: "key-people",
          path: "/people-intelligence/key-people",
        },
        // {
        //   text: "Send outreach",
        //   active: "/",
        //   path: "/people-intelligence/send-outreach",
        // },
      ],
    },
    {
      heading: "News Intelligence",
      image: news,
      subHeadings: [
        {
          text: "Hot Topics",
          active: "home-news",
          path: "/news-intelligence/home-news",
        },
        {
          text: "Companies in news",
          active: "company-in-news",
          path: "/news-intelligence/company-in-news",
        },
        {
          text: "Deals in news",
          active: "deals-news",
          path: "/news-intelligence/deals-news",
        },
        {
          text: "Funding news",
          active: "funding",
          path: "/news-intelligence/funding",
        },
        {
          text: "Sectors in news",
          active: "sectors",
          path: "/news-intelligence/sectors",
        },
        {
          text: "ESG news",
          active: "esg",
          path: "/news-intelligence/esg",
        },
      ],
    },
    {
      heading: "Community Pages",
      image: community,
      subHeadings: [
        {
          text: "Leading Investors",
          active: "leading-investors",
          path: "/leaderboards/leading-investors",
        },
        {
          text: "Leading Sectors",
          active: "leading-sectors",
          path: "/leaderboards/leading-sectors",
        },
        // {
        //   text: "Decacorns",
        //   active: "/",
        //   path: "/under-construction",
        // },
        {
          text: "Unicorns",
          active: "unicorns",
          path: "/leaderboards/unicorns",
        },
        {
          text: "Soonicorns",
          active: "soonicorns",
          path: "/leaderboards/soonicorns",
        },
        {
          text: "Shark Tank Featured",
          active: "shark-tank-companies",
          path: "/leaderboards/shark-tank-companies",
        },
      ],
    },
  ];
  const { "*": activeText } = useParams();
  const activatedText = window.location.pathname?.split("/")[2];
  const activatedHeading = window.location.pathname?.split("/")[1];
  const initialActiveHeading = {};
  const [activeHeading, setactiveHeading] = useState(initialActiveHeading);

  data.forEach((section, index) => {
    initialActiveHeading[index] = section.subHeadings.some((subheading) => subheading.active === activatedText);
  });
  const openHeading = (index) => {
    if (initialActiveHeading) {
      setactiveHeading({
        ...initialActiveHeading,
        [index]: !activeHeading[index],
      });
    } else {
      setactiveHeading({
        [index]: !activeHeading[index],
      });
    }
  };

  return (
    <div
      className={`w-1/6 bg-white relative mt-20  rounded-e-xl lg:block sm:hidden  overflow-hidden`}
      style={{ transition: "width 0.5s ease-out" }}
    >
      <div className=" h-[calc(100vh-5rem)] flex flex-col  ">
        <div
          // mt-4 pt-4
          className="bg-white w-full h-full"
        // style={{ boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)", }}
        >
          <div className="h-full pt-2 overflow-auto transition-all duration-500 ease">
            <ul className="max-h-56 transition-all duration-500 ease">
              {data?.map((text, index) => (
                <li
                  className="px-5 py-2 flex flex-col w-full font-roboto transition-all duration-500 ease  max-h-fit"
                  key={index}
                >
                  {text.heading && (
                    <div
                      className="flex justify-between items-center w-full cursor-pointer transition-all duration-500 ease"
                      onClick={() => {
                        openHeading(index);
                        // setHideSidebar((pre) => {
                        //   if (pre) {
                        //     return false;
                        //   }
                        // });
                      }}
                    >
                      <div className="flex gap-5">
                        <img
                          alt="image"
                          loading="lazy"
                          className="w-5"
                          src={`${text.image}`}
                        />

                        <p className=" w-max text-sm text-gray-600">
                          {text.heading}
                        </p>
                      </div>
                      <img
                        loading="lazy"
                        alt="image"
                        src={arrow}
                        className="w-6 h-6 self-end"
                      />
                    </div>
                  )}

                  <ul
                    className={`grid overflow-hidden py-1 w-full transition-all duration-500 ease ${activeHeading[index] ||
                      text.subHeadings[0].text === "Home"
                      ? "opacity-1 grid-rows-[1fr]"
                      : "opacity-0 grid-rows-[1fr] max-h-0"
                      } `}
                  >
                    {text.subHeadings.map((subheading, i) => (
                      <li
                        className="text-xs  w-full transition-all duration-500 ease  max-h-fit"
                        key={i}
                      >
                        <a href={subheading.path} className="flex">
                          {subheading?.image && (
                            <img
                              loading="lazy"
                              alt="image"
                              className="w-5 mr-4"
                              src={`${subheading.image}`}
                            />
                          )}
                          <p
                            className={`w-full ${subheading?.image ? "ml-1 pl-0" : "pl-10"
                              } py-2 hover:bg-gray-100 ${activatedText?.toLowerCase() ===
                                subheading.active?.toLowerCase()
                                ? "text-primaryBlue bg-gray-100 font-semibold rounded-md"
                                : "text-black"
                              }`}
                          >
                            {subheading.text}
                          </p>
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="pt-1">
                    <hr />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className=" w-full relative h-12 bg-transparent">
        <div
          className="w-10 h-10 m-2 absolute right-0"
          onClick={() => setHideSidebar(pre => !pre)}>
          <img alt="image" src={openCloseArrow} className={`p-2 fill-primaryBlue ${ hideSidebar ? " rotate-0" : " rotate-180"}`} />
        </div>
      </div> */}
    </div>
  );
};

export default Sidebar;
