import React, { useState } from "react";
import Loader from "../tearSheet/loader";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema, emailSchema } from "../../schemas";
import visibility_off from "../../assets/visibility_off.svg";
import visibility_on from "../../assets/visibility_on.svg";
import { useAuth } from "./authContext";
import { FORGET_PASSWORD_REQUEST } from '../../constant/api';
import factacyIcon from "../../assets/Icons/Insights.svg"
const ForgetComponent = ({ setForgetPageOpen }) => {
  const useNotExistError = "something went wrong";
  const linkSentSuccessfully = "Reset link sent";

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [input, setInput] = useState("");

  const requestRestLink = async (values) => {
    try {
      setLoading(true);
      const res = await fetch(`${FORGET_PASSWORD_REQUEST}?email=${values.email}&site=${window.location.host}`);
      if (res.ok) {
        const data = await res.json();
        setResponse(data.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const initialValues = {
    email: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      validationSchema: emailSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        requestRestLink(values);
      },
    });

  return (
    <div className="fadeIncrease">
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <p className=" text-md capitalize text-[#000000] font-medium">
                Email
              </p>
              <span className={`text-sm text-[#ff0000]`}>
                {errors.email && touched.email ? errors.email : " "}
              </span>
            </div>
            <input
              className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
              type="email"
              autoComplete="off"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className=" flex justify-center ">
          {loading ? (
            <Loader />
          ) : response ? (
            response === linkSentSuccessfully ? (
              <span>
                Password reset link send successfully. check your inbox
              </span>
            ) : (
              <div className=" flex flex-col items-center justify-center mt-2">
                <span className=" text-[#FF0000] text-sm font-semibold mb-1">
                  This email doesn't exist. Please enter correct email id
                </span>
                <button
                  type="submit"
                  className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]"
                >
                  Get Link
                </button>
              </div>
            )
          ) : (
            <button
              type="submit"
              className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]"
            >
              Get Link
            </button>
          )}
        </div>
      </form>
      {
        <p className=" mt-4 text-md">
          Have an account?{" "}
          <span
            className=" text-buttonCTA font-semibold cursor-pointer"
            onClick={() => setForgetPageOpen(false)}
          >
            Login
          </span>
        </p>
      }
    </div>
  );
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inFocus, setInFocus] = useState(false);
  const [isForgetPageOpen, setForgetPageOpen] = useState(false);

  const { login, error } = useAuth();
  const Navigate = useNavigate();

  const loginFunction = async (userData) => {
    try {
      setLoading(true);
      await login(userData);
      setLoading(false);
    } catch (error) {
      console.error("Login failed:", error);
      setLoading(false);
    }
    return "";
  };

  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      validationSchema: loginSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        loginFunction(values);
      },
    });

  return (
    <div className=" h-screen w-screen flex flex-col justify-evenly items-center">
            <div className="w-full fixed pt-4 top-0 left-4 ">
      <img className="lg:w-80 w-44 pb-4" src={factacyIcon} />

      </div>

      <div className=" overflow-y-auto lg:rounded-xl shadow-xl items-center p-8 min-h-[420px] bg-[#fff] border-[1px] w-full  border-[#f1f1f1] lg:w-[24rem]">

        {isForgetPageOpen ? (
          <>
            <ForgetComponent setForgetPageOpen={setForgetPageOpen} />
          </>
        ) : (
          <div className="fadeIncrease">
            <h1 className=" text-3xl mb-4 font-bold">
              <span className=" text-primaryBlue">Login.</span>
            </h1>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <div className=" flex-1 flex flex-col gap-2">
                  <div className=" flex gap-2 items-end">
                    <p className=" text-md capitalize text-[#000000] font-medium">
                      Email
                    </p>
                    <span className={`text-sm text-[#ff0000]`}>
                      {errors.email && touched.email ? errors.email : " "}
                    </span>
                  </div>
                  <input
                    className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                    type="email"
                    autoComplete="off"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className=" flex-1 flex flex-col gap-2">
                  <div className=" flex gap-2 items-end">
                    <p className=" text-md capitalize text-[#000000] font-medium">
                      Password
                    </p>
                    <span className={`text-sm text-[#ff0000]`}>
                      {errors.password && touched.password
                        ? errors.password
                        : " "}
                    </span>
                  </div>
                  <div
                    className={` w-full flex rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem] ${inFocus ? " outline-primaryBlue outline-[0.1rem]" : ""
                      } `}
                    onBlur={() => setInFocus(false)}
                  >
                    <input
                      className=" flex-1 outline-none border-none"
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="password"
                      onFocus={() => setInFocus(true)}
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                    />
                    <img
                      className=" cursor-pointer h-5"
                      src={showPassword ? visibility_on : visibility_off}
                      alt="visibility_icon"
                      onClick={() => setShowPassword((pre) => !pre)}
                    />
                  </div>
                </div>
                <span
                  className=" text-buttonCTA font-semibold cursor-pointer text-sm"
                  onClick={() => setForgetPageOpen(true)}
                >
                  Forgot Password ?
                </span>
              </div>

              {error &&
                <p className={`text-sm text-[#ff0000]`}>
                  {error}
                </p>}
              <div className=" flex justify-center ">
                {loading ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]"
                  >
                    Login
                  </button>
                )}
              </div>
            </form>

            <div>
              <p className=" mt-4 text-md">
                Do not have an account? <span className="text-buttonCTA font-semibold cursor-pointer" onClick={() => Navigate("/register")}>New user</span>
              </p>
              {/* <p
                  className=" border-buttonCTA border-[1px] text-buttonCTA mt-1 w-fit px-3 py-1 rounded-lg font-semibold cursor-pointer"
                  onClick={() => Navigate("/register")}
                >
                  Join the waitlist
                </p> */}
            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Login;

