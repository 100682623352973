import React from "react";
import AltPortfolio from "./altPortfolio";
import { useParams } from "react-router";
// import { getInvestor } from "../../redux/ActionCreators/investorActions";
import { useDispatch, useSelector, connect } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import useFetchInvestorActions from "../../hooks/useFetchInvestorActions";
import { GET_ALL_DEALS } from "../../constant/api";
import Loader from "../tearSheet/loader";

const AltPortfolioPage = () => {
  const {getInvestor} = useFetchInvestorActions();
  const { investor } = useParams();
  const [sortedData, setSortedData] = useState([]);
  const investorState = useSelector((state) => state.investor);
  const investorData = investorState.investorData[investor];
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getInvestor(investor);
  }, [investor]);

  useEffect(() => {
    const fetchInvestorDeals = async () => {
      if (
        investorData &&
        investorData?.length > 0 &&
        investorData[0]?.Investor_Deal_Ids
      ) {
        setLoading(true);
        const dealIds = investorData[0].Investor_Deal_Ids;
        const formattedIds =
          dealIds && dealIds?.slice(0,40)?.join(",");  
        try {
          const responses = await axios.get(`${GET_ALL_DEALS + formattedIds}`
          );
          const sortedData = responses.data?.slice().sort((a, b) => {
            const lastDateA =
              a.Unique_date_time && a.Unique_date_time.length > 0
                ? a.Unique_date_time[a.Unique_date_time.length - 1]
                : null;
            const lastDateB =
              b.Unique_date_time && b.Unique_date_time.length > 0
                ? b.Unique_date_time[b.Unique_date_time.length - 1]
                : null;

            if (lastDateA !== null && lastDateB !== null) {
              return lastDateB - lastDateA; // Sort in descending order based on epoch time
            } else if (lastDateA === null && lastDateB !== null) {
              return 1; // Put empty or non-existent dates at the end
            } else if (lastDateA !== null && lastDateB === null) {
              return -1; // Put empty or non-existent dates at the end
            }
            return 0;
          });
          setSortedData(sortedData);
        }

        catch { }
        setLoading(false);

      }
    };
    fetchInvestorDeals();
  }, [investorData]);

  return (
    <div className=" px-4 py-0 flex flex-col gap-4">
      <div
        className="flex w-full rounded-xl bg-white lg:px-8 sm:px-4 lg:py-6 sm:py-3 flex-col gap-3"
        style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
      >
        <div className="flex items-center">
          <p className="font-roboto lg:text-3xl sm:text-lg font-bold leading-9">
            {investorData && investorData[0].Investor}
          </p>
        </div>
      </div>

      <div >
      {loading ? <div className="w-full h-full flex items-center justify-center"> <Loader /> </div>: 
        <AltPortfolio data={sortedData} />}
      </div>
    </div>
  );
};


export default AltPortfolioPage;
