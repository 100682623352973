import axios from "axios";
import { GET_CREDIT_HISTORY } from "../../constant/api";
import { useState } from "react";

const useProfile = () => {
  const [creditHistory, setCreditHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCreditHistory = async () => {
    setLoading(true);
    const response = await axios.post(GET_CREDIT_HISTORY, {
      email: sessionStorage.getItem("email")
    });
    setCreditHistory(response.data);
    setLoading(false);
    return creditHistory;
  };
  return { loading, creditHistory, getCreditHistory };
};

export default useProfile;
