import { useRef } from "react";

const useDebounce = (funcX, delay) => {

    const optimizeFunction = useRef((() => {
        let timer = 0;
        return (...input) => {

            if (timer) clearTimeout(timer);

            const id = setTimeout(() => {
                funcX(...input);
            }, delay);

            timer = id;
        }
    })());

    return optimizeFunction.current;
}
export default useDebounce;