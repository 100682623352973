import React from "react";
import { motion } from "framer-motion";
import home from "../../assets/home-fill.svg";
import home_line from "../../assets/home-line.svg";
import notif_line from "../../assets/notification-2-line.svg";
import notif from "../../assets/notification-2-fill.svg";
import search_line from "../../assets/search-line.svg";
import search from "../../assets/search-fill.svg";
import message_line from "../../assets/questionnaire-line.svg";
import message from "../../assets/questionnaire-fill.svg";
import eye_line from "../../assets/eye-line.svg";
import eye from "../../assets/eye-fill.svg";
import user_line from "../../assets/account-circle-line.svg";
import user from "../../assets/account-circle-fill.svg";
import logout from "../../assets/logout-circle-line.svg";
import { useState } from "react";
import Home from "./Home";
import Notifications from "./Notifications";
import Search from "./Search";
import Requests from "./Requests";
import Membership from "./Membership";
import Account from "./Account";
import Logout from "./Logout";

const Profile = () => {
    const [activeSection, setActiveSection] = useState("home");
    const tabs = {
        home: [home_line, home, <Home />],
        notif: [notif_line, notif, <Notifications />],
        search: [search_line, search, <Search />],
        requests: [message_line, message, <Requests />],
        membership: [eye_line, eye, <Membership />],
        account: [user_line, user, <Account />],
        logout: [logout, logout, <Logout />],
    };

    return (
        <motion.div
            className="font-roboto pt-20  px-4 lg:pt-32 lg:px-40 h-screen overflow-y-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
        >
            <div className="h-max flex lg:flex-row flex-col gap-4">
                <div
                    className="bg-white h-max flex flex-row lg:flex-col w-fit overflow-x-auto lg:w-20 items-center p-5 rounded-lg gap-8 "
                    style={{
                        boxShadow:
                            "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
                    }}
                >
                    {Object.keys(tabs).map((item, i) => (
                        <img
                            src={activeSection === item ? tabs[item][1] : tabs[item][0]}
                            key={i}
                            onClick={() => setActiveSection(item)}
                            className="w-7 h-7 cursor-pointer"
                        />
                    ))}
                </div>
                <div className="w-full">{tabs[activeSection][2]}</div>
            </div>
        </motion.div>
    );
};

export default Profile;
