import React from "react";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../tearSheet/loader";
import DealCard from "../../pages/dealCard";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import useFetchStageActions from "../../hooks/useFetchStageActions";
import IndividualNews from "../IndividualNews";
import { goToInvestorPage } from "../../constant/function";
import { GETARTS } from "../../constant/api";

const StagePage = ({ stage }) => {
  const shadow = {
    boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
  }
  const getStage = useFetchStageActions();
  // const { stage } = useParams();
  const { user } = useAuth();
  const [allArticlesData, setAllArticlesData] = useState([]);
  const [isNewsLoading, setNewsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState("deals");
  const stageState = useSelector((state) => state.stage);
  const stageData = stageState.stageData[stage];
  const [investors, setInvestors] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // GET stage FUNCTION
  useEffect(() => {
    getStage(stage);
    setAllArticlesData(false);
    setActiveSection("deals")

    if(activeSection === 'news' ){
      invesorNews();
    }
    if(activeSection === 'investors' ){
      getInvestors();
    }
  }, [stage]);

  const getInvestors = () => {
    const investors =
      stageData && stageData.map((stage) => stage.Corrected_Investors).flat();
    setInvestors(investors);
  };

  const handleSectionClick = useCallback(
    (section) => {
      setActiveSection(section);
    },
    [activeSection]
  );


  const invesorNews = async () => {
    if (stageData?.length > 0) {
      try {
        setNewsLoading(true);
        const extractedArtIds = stageData.map((deal) => deal.Art_Id).flat();
        const formattedIds =
          extractedArtIds && extractedArtIds.join(",");

        const response = await axios.get(`${GETARTS + formattedIds}`);
        const sortedData = response.data.flat().sort((a, b) => {
          const epochTimestampA = a?.Unique_date_time;
          const epochTimestampB = b?.Unique_date_time;

          if (epochTimestampA && epochTimestampB) {
            return epochTimestampB - epochTimestampA;
          }
        });
        setAllArticlesData(sortedData);
        setNewsLoading(false);
      } catch { }
    }
  };


  const showData = () => {
    const stageData = stageState.stageData[stage];
    if (stageState.stageData[stage]) {
      return (
        <div>
          {stageData && (
            <div className="flex flex-col w-full px-4 gap-4" >
              <div
                className="flex w-full items-center bg-white rounded-xl justify-between"
              // style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
              >
                <div className=" lg:px-8 sm:px-4 lg:py-6 sm:py-3 flex items-center gap-6 w-full rounded-xl"
                  style={shadow}
                >
                  {/* // <img src={personn} className='lg:w-[70px] lg:h-[70px] sm:w-[60px] sm:h-[60px] object-contain' />  */}
                  <div className="flex flex-col ">
                    <p className="font-roboto lg:text-3xl sm:text-lg font-bold leading-9">
                      {stage}
                    </p>
                  </div>
                </div>
                <div>

                </div>
              </div>

              {/* details */}
              <div className="lg:w-full sm:w-full h-max bg-[#FAFAFA] rounded-xl overflow-hidden"
                style={shadow}>
                <div className="flex justify-between text-center w-full sm:overflow-auto lg:overflow-auto">
                  <div
                    className={`flex flex-col w-full item lg:text-sm sm:text-xs items-center  px-4 py-4  font-roboto hover:bg-white ${activeSection === "deals"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      } `}
                    onClick={() => handleSectionClick("deals")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Deals
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs items-center  px-4 py-4  font-roboto hover:bg-white ${activeSection === "investors"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      } `}
                    onClick={() => {
                      handleSectionClick("investors");
                      getInvestors();
                    }}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Investors
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs items-center px-4 py-4 font-roboto hover:bg-white ${activeSection === "news"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => {
                      handleSectionClick("news");
                      invesorNews();
                    }}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      News
                    </div>
                  </div>
                </div>

                {user.isAuthenticated === true ? (
                  <>
                    {/* investors */}
                    <div
                      className={`section ${activeSection === "investors" ? "active" : "hidden"
                        } bg-white sm:w-full flex lg:p-8 sm:p-2 gap-5 flex-wrap w-full`}
                      // style={{
                      //   boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
                      // }}
                      style={shadow}
                    >
                      <table className="table-auto border w-full">
                        <thead>
                          <tr>
                            <th className="p-2">Investors</th>
                          </tr>
                        </thead>
                        <tbody>
                          {investors &&
                            investors.length > 0 &&
                            investors.map((investor, index) => (
                              <tr key={index}>
                                {investor &&
                                  investor.length > 1 &&
                                  investor.map(
                                    (value, idx) =>
                                      idx === 1 && (
                                        <td
                                          className="p-2 border-b font-semibold hover:text-primaryBlue hover:underline cursor-pointer"
                                          onClick={() =>
                                            goToInvestorPage(investor[1], investor[0])
                                          }
                                          key={idx}
                                        >
                                          {value}
                                        </td>
                                      )
                                  )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    {/* deals */}

                    <div
                      className={`section ${activeSection === "deals" ? "active" : "hidden"
                        } bg-white w-full  p-4`}
                    // style={{
                    //   boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
                    // }}
                    >
                      <DealCard sortedData={stageData} />
                    </div>

                    {/* news */}
                    <div
                      className={`section ${activeSection === "news" ? "active" : "hidden"
                        } key_people bg-white sm:w-full  gap-4 flex flex-col sm:p-4 lg:p-8`}
                    // style={{
                    //   boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
                    // }}
                    >
                      <p className="font-medium text-[25px] tracking-tight leading-9">
                        News
                      </p>

                      {isNewsLoading && (
                        <div className="flex items-center justify-center h-full">
                          <Loader />
                        </div>
                      )}
                      {!isNewsLoading && (
                        <div className="flex flex-col w-full gap-5">
                          {allArticlesData
                            ? allArticlesData.map((news, index) => {
                              if (!news || !news?.Unique_date_time) {
                                return null;
                              }
                              return (<IndividualNews news={news} />)
                            })
                            : "No results found :("}
                          <div className="flex justify-center p-3"></div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center flex-col gap-3 h-screen">
                    Please login first <LoginButton className="w-5" />
                  </div>
                )}
              </div>

              {showErrorMessage && (
                <div className="absolute bottom-0 right-0 lg:m-10 sm:m-4 lg:p-3 sm:p-2 lg:text-base sm:text-sm border-blue border-2 bg-slate-100 rounded-lg font-semibold font-roboto">
                  We are expanding our coverage daily. Please check back again
                  later.
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    if (stageState.loading) {
      return (
        <div className="flex flex-col items-center h-screen">
          {" "}
          <Loader />{" "}
        </div>
      );
    }

    if (stageState.errorMsg !== "") {
      return <p>{stageState.errorMsg}</p>;
    }

    return <p>Error getting stages Data</p>;
  };

  // return <div className="px-4 py-2 sm:w-full lg:w-full">{showData()}</div>;
  return <div className="sm:w-full lg:w-full">{showData()}</div>;
};


export default StagePage;