import React from 'react'

const Requests = () => {
        return (
            <div className={`section  flex flex-col w-full h-max bg-white rounded-lg p-6 lg:p-8 gap-4 lg:gap-10`} style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                <p className='text-3xl font-bold'>All Requests</p>
                <div className='flex bg-[#E8F5FF] rounded-full w-max gap-3 lg:gap-6 p-3  text-sm text-primaryBlue items-center'>
                    <div className='bg-white py-2 px-3 rounded-full'>
                        <p>Open Requests</p>
                    </div>
                    <div>
                        <p>Closed Requests</p>
                    </div>
                    <div>
                        <p>All Requests</p>
                    </div>
                </div>
                <div className='flex flex-col gap-8'>
                    <div className='flex lg:flex-row gap-3 lg:gap-0 flex-col'>
                        <div className='w-full lg:w-1/2 '>
                            <div className='px-4 py-2 bg-[#E8F5FF] w-max rounded-full'>Received</div>
                        </div>
                        <div className='lg:w-1/2 text-lg flex flex-col gap-2'>
                            <p className='text-base text-gray-700'>11 May 2023, 11:32AM</p>
                            <p className='text-xl font-semibold'>Lorem ipsum dolor sit amet</p>
                            <p className='text-base text-gray-700'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to.</p>
                        </div>
                    </div>
                    <hr />
                    <div className='flex lg:flex-row gap-3 lg:gap-0 flex-col'>
                        <div className='lg:w-1/2 '>
                            <div className='px-4 py-2 bg-yellow w-max rounded-full'>Processing</div>
                        </div>
                        <div className='lg:w-1/2 text-lg flex flex-col gap-2'>
                            <p className='text-base text-gray-700'>11 May 2023, 11:32AM</p>
                            <p className='text-xl font-semibold'>Lorem ipsum dolor sit amet</p>
                            <p className='text-base text-gray-700'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to.</p>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        )
    }

export default Requests
