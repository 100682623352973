import React from 'react'

const Notifications = () => {
    return (
        <div className={`section  flex flex-col w-full h-max bg-white rounded-lg p-4 lg:p-8 gap-10`} style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
            <p className='text-3xl font-bold'>All Notifications</p>
            <div className='flex justify-between'>
                <div className='flex bg-[#E8F5FF] rounded-full w-full gap-4 p-3 text-sm text-primaryBlue items-center'>
                    <div className='bg-white py-2 px-3 rounded-full'>
                        <p>Unread Notifications</p>
                    </div>
                    <div>
                        <p>Read Notifications</p>
                    </div>
                </div>
                <p className='text-sm text-primaryBlue'>Mark as read</p>
            </div>
            <div className='flex flex-col gap-8'>
                <div className='flex lg:flex-row flex-col'>
                    <div className='w-full lg:w-1/3'>11 May 2023, 11:32AM</div>
                    <div className='w-full lg:w-2/3 text-gray-600'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</div>
                </div>
                <hr />
                <div className='flex lg:flex-row flex-col'>
                <div className='lg:w-1/3'>11 May 2023, 11:32AM</div>
                    <div className='lg:w-2/3 text-gray-600'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default Notifications
