import React, { useEffect, useState } from "react";
import {
  PATENT_SEARCH_IDS,
} from "../constant/api";
import { goToSingleNewsPage, howOldTimeUpdater } from "../constant/function";
import DealCarouselSlider from "../pages/DealCarouselSlider";
// import Loader from "../components/tearSheet/loader";
import { Inventor_Pill, Investor_Pill } from "../constant/Tiny_Components";

const IndividualLeadingSector = ({ data, dealsNews, sortedData, dealIds, investors }) => {
  const sectorName = data?.Sectors;

  const patentIds = Object.values(data?.Application_No_List).join(",");

  const [loading, setLoading] = useState(false);

  const [inventors, setInventors] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    if (patentIds) {
      (async () => {
        try {
          setLoading(true);
          const res = await fetch(PATENT_SEARCH_IDS + patentIds);
          if (res.ok) {
            const data = await res.json();
            const inventors = data
              ?.map((item) => item.Inventor_Name)
              .flat(1)
              .slice(0, 3);
            setInventors(inventors);

            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      })();
    }
  }, [patentIds]);

  useEffect(() => {
    const news = dealsNews
      ?.filter(
        (item) =>
          Object.hasOwn(item, "Deals") &&
          item.summary_IC?.toLowerCase() === sectorName.toLowerCase()
      )
      .slice(0, 3);
    setNews(news);
  }, [dealsNews]);

  return (
    <div className="bg-white p-4 rounded-xl">
      <h1 className="text-2xl font-bold">{sectorName}</h1>
      <div className=" mt-4 flex sm:flex-col lg:flex-row gap-6">
        <div className="flex gap-5 flex-col lg:w-3/5">
          <div className="flex gap-2 flex-col w-full">
            <h4 className=" text-lg font-medium">Leading Investors</h4>
            <div className=" flex gap-4 flex-wrap">
              {investors?.map((item, index) => (
                <Investor_Pill key={index} investor={item} />
              ))}
            </div>
          </div>
          <div className="w-full">
            <h4 className=" text-lg font-medium">
              Recent Deals in {sectorName}
            </h4>
            <div className=" w-full">
              {sortedData && <DealCarouselSlider sortedData={sortedData} dealIds={dealIds.split(',')} />}
            </div>
          </div>
        </div>
        <div className="lg:w-2/5 flex gap-12 flex-col">
          <div className="flex gap-2 flex-col">
            <h4 className=" text-lg font-medium">Leading Inventors</h4>
            <div className=" flex gap-4 flex-wrap">
              {inventors?.map((item, index) => (
                <Inventor_Pill inventor={item} key={index} />
              ))}
            </div>
          </div>
          <div>
            <h4 className=" text-lg font-medium mb-3">
              Recent Deals News in {sectorName}
            </h4>
            <div className="flex flex-col gap-3">
              {news?.map((item, index) => (
                <div className="flex flex-col pt-1" key={index}>
                  <p
                    className="font-semibold hover:text-primaryBlue cursor-pointer hover:underline"
                    onClick={() =>
                      goToSingleNewsPage(item?.Art_Id, item?.headline)
                    }
                  >
                    {item.headline}
                  </p>
                  <div className="flex items-center text-sm gap-2 font-roboto text-[#71717A]">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49984 1.73926V3.40592H12.4998V1.73926H14.1665V3.40592H17.4998C17.9601 3.40592 18.3332 3.77902 18.3332 4.23926V17.5726C18.3332 18.0328 17.9601 18.4059 17.4998 18.4059H2.49984C2.0396 18.4059 1.6665 18.0328 1.6665 17.5726V4.23926C1.6665 3.77902 2.0396 3.40592 2.49984 3.40592H5.83317V1.73926H7.49984ZM16.6665 10.0726H3.33317V16.7393H16.6665V10.0726ZM5.83317 5.07259H3.33317V8.40592H16.6665V5.07259H14.1665V6.73926H12.4998V5.07259H7.49984V6.73926H5.83317V5.07259Z"
                        fill="#71717A"
                      />
                    </svg>
                    <p>{howOldTimeUpdater(item?.Unique_date_time)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualLeadingSector;
