import React from 'react'
import search_line_black from "../../assets/search-line - Copy.svg"
import building from "../../assets/building-fill.svg"
import contact from "../../assets/contacts-fill.svg"
import news from "../../assets/file-list-3-fill.svg"

const Search = () => {
    return (
        <div className={`section flex flex-col w-full h-[700px] bg-white rounded-lg p-4 lg:p-8 gap-7`} style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
            <form className='w-full border-2 rounded-full bg-gray-100'>
                <div className='flex items-center gap-2 p-4'>
                    <img className='w-5 h-5' src={search_line_black} />
                    <input className='w-full  appearance-none focus:outline-none ' required type='text' placeholder='Search any company, contact or news event...'></input>
                </div>
            </form>
            <div className='flex gap-3 flex-wrap lg:gap-5'>
                <div className='bg-[#E8F5FF] rounded-full p-3 gap-2 flex items-center'>
                    <img className='w-4 h-4' src={building} />
                    <p className='text-sm text-primaryBlue'>Companies</p>
                </div>
                <div className='bg-[#E8F5FF] rounded-full p-3 gap-2 flex items-center'>
                    <img className='w-4 h-4' src={contact} />
                    <p className='text-sm text-primaryBlue'>Contacts</p>
                </div>
                <div className='bg-[#E8F5FF] rounded-full p-3 gap-2 flex items-center'>
                    <img className='w-4 h-4' src={news} />
                    <p className='text-sm text-primaryBlue'>News events</p>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col justify-between gap-12 w-full'>
                <div className='flex flex-col w-full lg:w-1/2 gap-8'>
                    <p className='text-xl font-semibold'>Trending Companies</p>
                    <hr />
                    <hr />
                    <hr />

                </div>
                <div className='flex flex-col w-full lg:w-1/2 gap-8'>
                    <p className='text-xl font-semibold'>Recently Viewed</p>
                    <hr />
                    <hr />
                    <hr />

                </div>
            </div>
        </div>
    )
}

export default Search
