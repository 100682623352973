import axios from "axios";
import React, { useEffect, useState } from "react";
import { goToCompanyPage } from "../../constant/function";

const TrendingCompanies = () => {
  const [trendingcos, setTrendingcos] = useState([]);

  useEffect(() => {
    const fetchTrendingcos = async () => {
      try {
        const response = await axios.get(
          "https://factacyinsights.in/trending-companies"
        );
        setTrendingcos(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchTrendingcos();
  }, []);
  return (
    <div className="flex w-full mb-3 mx-3  gap-3">
      <div className=" px-2 py-1  cursor-pointer rounded-full h-fit  items-center w-fit  flex ">
      <h1 className="text-lg font-semibold text-gray-800 w-max"> Companies in News</h1>

      </div>
      <div className="overflow-hidden hover:overflow-x-auto flex h-fit rounded-lg  py-1">
        <div className="flex   whitespace-nowrap animate-slide hover:animate-none  gap-3 w-full h-full">
          {trendingcos?.map((co, i) => (
            <div className="px-3 py-1 gap-2 cursor-pointer rounded-full items-center bg-white flex " style={{   boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)" }}
              onClick={() =>
                goToCompanyPage(
                  co.Reference_Id,
                  [co.Legal_Name],
                  co.Brand_Id,
                  [co.Company_Name]
                )} key={i}
            >
              {co.Logo && 
              <div className="w-12 h-7">
              <img src={co.Logo} className="w-12 h-7 object-contain" />
              </div>}
              <p className="font-semibold w-max text-lg">{co.Company_Name} {" "}</p>
              {/* {co.Legal_Name && 
            <p className="text-gray-700 w-max text-sm">({co.Legal_Name})</p>} */}
            </div>
          ))}
        </div>
      </div>
    </div>


  );
};

export default TrendingCompanies;
