import React, { useEffect, useState } from "react";
import Summary from "./summary";
import Financials from "./financials/financials";
import PotentialInvestors from "./potentialInvestors";
import KeyPeople from "./keyPeople";
import CompetitorGroot from "./competitorGroot";
import EsgScore from "./esgScore";
import PatentsGroot from "./patentsGroot";
import NewsInsights from "./newsInsights";
import LoginComponent from "../loginComponent";
import { useAuth } from "../auth/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useFetchCompany from "../../hooks/Tearsheet/useFetchCompany";
import FinancialsNews from "./financialsNews";
import useAddMetaTags from "../../hooks/MetaTagsAdd/useAddMetaTags";

const TearsheetComponent = ({ input, dataToMap, company, binput, companyData }) => {
  const queries = ["summary", "financials", "proposed investors", "key people", "competitors", "esg/csr", "patents", "news insights"];
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialActiveSection = queries.includes(searchParams.get("info")) ? searchParams.get("info") : "summary";
  if (!queries.includes(searchParams.get("info"))) {
    searchParams.set("info", "summary")
    window.history.replaceState(null, null, `?${searchParams.toString()}`)
  }

  const [activeSection, setActiveSection] = useState(initialActiveSection);
  const [metaTagsProps, setMetaTagProps] = useState({})
  const {
    getPatentsData,
    getFinancials,
    getProposedInvestors,
    getPersonData,
    getEsgData,
  } = useFetchCompany(input, binput);
  const loading = useSelector((state) => state.company.loading);
  const newsArticles = useSelector((state) => state.company)
    .news?.[0]?.slice()
    ?.sort((a, b) => b.Unique_date_time - a.Unique_date_time);
  const newsData = useSelector((state) => state.company).newsData[input];
  const competitorDetails = useSelector((state) => state.company).competitorDetails[input];

  const metaTagsMapping = {
    data: {
      name: company?.Brand ?? company?.Company_Name,
      keywords: company?.Keywords
    },
    summary: {
      title: `Company Snapshot, Founder, Number of Employees`,
      summary: company?.Summary,
    },
    financials: {
      title: `Revenue, Funding, Investors, Financials`,
      summary: newsArticles?.filter(news => news.hasOwnProperty('Deals') && news.visibility === "YES")?.[0]?.summary_of_article ?? `Factacy Insights has detailed financials for ${company?.Brand ?? company.Company_Name}. This can be accessed on demand. To request ${company?.Brand ?? company.Company_Name}'s revenue, EBIDTA, PAT, Balance sheet and Shareholder information, Please login and submit the request.`
    },
    "proposed investors": {
      title: `Investors, Proposed Investors`,
      summary: company?.Inv_Description,
    },
    "key people": {
      title: `Key People, Founders, Officers`,
      summary: company?.KP_Description,
    },
    competitors: {
      title: `Competitors, Alternatives`,
      summary: company?.Comp_Description,
    },
    "esg/csr": {
      title: `ESG Rankings, Ratings, News`,
      summary: company?.ESG_Description,
    },
    patents: {
      title: `Patents, Intellectual Property`,
      summary: company?.Patent_Description,
    },
    "news insights": {
      title: `News, Recent News, Realtime News Summary`,
      summary: `Recent news for ${company?.Brand ?? company?.Company_Name} - ` + newsData?.Company_SOS,
    },
  };
  useEffect(() => {
    setMetaTagProps({
      props: metaTagsMapping.data, ...metaTagsMapping[activeSection],
    });
  }, [activeSection]);

  useAddMetaTags(metaTagsProps);


  const Tabs = {
    summary: <Summary data={[dataToMap]} cin={companyData?.Cin} lei={companyData?.Lei} competitorDetails={competitorDetails} setActiveSection={setActiveSection} input={input} businessNews={newsArticles} />,
    financials: user?.isAuthenticated ? <Financials input={input} loading={loading} getFinancials={getFinancials} companyData={companyData} company={company} /> : <><FinancialsNews newsArticles={newsArticles} company={company} /> <LoginComponent /> </>,
    "proposed investors": user?.isAuthenticated ? <PotentialInvestors loading={loading} getProposedInvestors={getProposedInvestors} binput={binput} /> : <LoginComponent />,
    "key people": user?.isAuthenticated ? <KeyPeople loading={loading} input={input} getPersonData={getPersonData} /> : <LoginComponent />,
    competitors: user?.isAuthenticated ? <CompetitorGroot company={company} companyId={input} brandId={binput} competitorDetails={competitorDetails} useAddMetaTags={useAddMetaTags} /> : <LoginComponent />,
    "esg/csr": <EsgScore getEsgData={getEsgData} input={input} loading={loading} />,
    patents: user?.isAuthenticated ? <PatentsGroot getPatentsData={getPatentsData} input={input} loading={loading} /> : <LoginComponent />,
    "news insights": <NewsInsights newsArticles={newsArticles} newsData={newsData} companyName={company.Brand ?? company.Company_Name} input={input} />,
  };
  const TabsNames = [
    "Summary",
    "Financials",
    "Proposed Investors",
    "Key People",
    "Competitors",
    "ESG/CSR",
    "Patents",
    "News Insights",
  ];

  return (
    <div>
      <div
        className="flex justify-between text-center w-full sm:overflow-auto lg:overflow-auto bg-[#FAFAFA] rounded-t-xl"
        style={{
          boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        {TabsNames.map((item, index) => (
          <div
            key={index}
            className={` lg:w-full sm:w-full  h-max bg-[#FAFAFA] flex flex-col w-full item lg:text-sm sm:text-xs items-center px-4 py-4  font-roboto hover:bg-white ${activeSection === item.toLowerCase()
              ? "bg-white text-primaryBlue font-bold relative z-100"
              : "text-[#A1A1AA]"
              } `}
            onClick={() => {
              setActiveSection(item.toLowerCase());
              searchParams.set("info", item.toLowerCase());
              navigate(`?info=${item.toLowerCase()}`);
            }}
          >
            <div className="cursor-pointer sm:w-max lg:w-full">{item}</div>
          </div>
        ))}
      </div>


      <div className="relative w-full z-200">
        {Tabs?.[activeSection]}
      </div>
    </div>
  );
};

export default TearsheetComponent;
