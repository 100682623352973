// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import factacy from "../../assets/Factacy-grayLogomark (1).png";
// import { handleSectorClick } from "../../constant/function";

// const SectorWiseActivity = () => {
//   const [sector, setSector] = useState([]);
//   const [filterText, setFilterText] = useState('');

//   useEffect(() => {
//     const fetchSectors = async () => {
//       const response = await axios.get("https://factacyinsights.in/main_sector");
//       setSector(response.data);
//     };
//     fetchSectors();
//   }, []);


//   return (
//     <div className="mx-4 my-2 rounded-lg p-6 flex flex-col gap-6 h-full">
//       <div className="flex justify-between ">
//         <p className="leading-9 font-bold text-2xl py-1">Sector wise Investor Activity</p>
//         {sector.length > 0 && (<div className="flex justify-between gap-2 items-center rounded-xl bg-white px-4 py-1">
//           <svg
//             className=" cursor-pointer"
//             width="23"
//             height="21"
//             viewBox="0 0 27 26"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
//               fill="#71717A"
//             />
//           </svg>
//           <input
//             placeholder="search for sectors..."
//             className="p-2 rounded-md w-[16rem] appearance-none focus:outline-none "
//             type="text"
//             value={filterText}
//             onChange={(e) => setFilterText(e.target.value)}
//           />
//         </div>)}
//       </div>

//       <div className="flex flex-wrap gap-8 pb-8">
//         {sector &&
//           sector.filter(item => item?.Main[1]?.toLowerCase().includes(filterText.toLocaleLowerCase())).map((sector, index) => (
//             <div
//               key={index}
//               className="flex gap-1 bg-white py-2 px-3 items-center rounded-full cursor-pointer hover:bg-gray-50 hover:scale-105 duration-200"
//               style={{
//                 boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
//               }}
//               onClick={() => handleSectorClick(sector.Main[0])}
//             >
//               <img src={sector.Sectors_Url ?? factacy} className="w-8 h-8" />
//               <p>{sector?.Main ? sector?.Main[1] : ""}</p>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default SectorWiseActivity;


import React, { useEffect, useState } from "react";
import axios from "axios";
import factacy from "../../assets/Factacy-grayLogomark (1).png";
import { handleSectorClick } from "../../constant/function";
import SectorPage from "../sectorIntelligence/sectorPage";
import { GET_MAIN_SECTORS } from "../../constant/api";

const SectorWiseActivity = () => {
  const [sector, setSector] = useState([]);
  const [sectorId, setSectorId] = useState('6f29de1a-ebef-4cd0-9357-6fa278fffe8b');
  //   const [filterText, setFilterText] = useState('');


  useEffect(() => {
    const fetchSectors = async () => {
      const response = await axios.get(GET_MAIN_SECTORS);
      setSector(response.data);
    };
    fetchSectors();
  }, []);


  return (
    <div className="mx-4 my-0 relative rounded-xl overflow-hidden bg-[#FAFAFA] flex">
      <div className=" w-[12em] py-2">
        <div className="fixed overflow-scroll w-[12em] h-[calc(100vh-6rem)]  flex flex-col">
          {sector?.map((item, index) =>
          (<div className="pl-3 py-3 flex items-center gap-2 hover:bg-white cursor-pointer"
            onClick={() => setSectorId(item?.Main?.[0])}
          >
            {item?.Sectors_Url ? (<img className=" h-5 w-5" src={item?.Sectors_Url} alt={item?.Main?.[1]} />) : (<div className=" h-5 w-5"></div>)}
            <span
              className={` lg:text-sm font-roboto ${sector === item?.Main?.[0] ? " bg-white text-primaryBlue" : "text-[#2d2d2d]"}`}
              key={index}>
              {item?.Main?.[1]}
            </span>
          </div>)
          )}
        </div>
      </div>
      <div className="overflow-x-scroll flex-1 bg-white w-full">
        <SectorPage sectorId={sectorId} />
      </div>
    </div>
  );
};

export default SectorWiseActivity;
