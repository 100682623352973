import React from 'react'

const Account = () => {
    return (
        <div className={`section  flex flex-col gap-4 w-full`}>
            <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                <p className='text-xl font-bold'>Your Details</p>
                <p className='text-gray-700'>Go ahead and update your personal details</p>
                <div className='flex flex-col gap-12'>
                    <div className='flex w-full gap-10'>
                        <div className='w-1/2 flex flex-col gap-2'>
                            <p className='text-sm'>First Name</p>
                            <input className='focus:outline-none p-4 w-full rounded-lg bg-gray-100' placeholder='John'></input>
                        </div>
                        <div className='w-1/2 flex flex-col gap-2'>
                            <p className='text-sm'>Last Name</p>
                            <input className='focus:outline-none p-4 w-full rounded-lg bg-gray-100' placeholder='Doe'></input>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-2'>
                        <p className='text-sm'>Email</p>
                        <input className='focus:outline-none p-4 w-full rounded-lg bg-gray-100' placeholder='Johndoe123@gmail.com'></input>
                    </div>
                    <p className='text-primaryBlue text-sm underline '>Save Changes</p>

                </div>
            </div>
            <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                <p className='text-xl font-bold'>Change Password</p>
                <p className='text-gray-700'>Go ahead and update your password</p>
                <div className='flex flex-col gap-12'>
                    <div className='lg:w-1/2 flex flex-col gap-2'>
                        <p className='text-sm'>Old Password</p>
                        <input className='focus:outline-none p-4 w-full rounded-lg bg-gray-100' placeholder='Johndoe123@gmail.com'></input>
                    </div>
                    <div className='flex w-full gap-10'>
                        <div className='lg:w-1/2 flex flex-col gap-2'>
                            <p className='text-sm'>New Password</p>
                            <input className='focus:outline-none p-4 w-full rounded-lg bg-gray-100' placeholder='John'></input>
                        </div>
                        <div className='lg:w-1/2 flex flex-col gap-2'>
                            <p className='text-sm'>Confirm Password</p>
                            <input className='focus:outline-none p-4 w-full rounded-lg bg-gray-100' placeholder='Doe'></input>
                        </div>
                    </div>

                    <p className='text-primaryBlue text-sm underline '>Update Password</p>

                </div>
            </div>
            <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                <p className='text-xl font-bold'>Email Notification</p>
                <p className='text-base text-gray-600'>Manage your personal notification settings</p>
            </div>
            <div className='h-max w-full bg-white rounded-lg p-6 lg:p-8 flex flex-col gap-4' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                <p className='text-xl font-bold'>Delete Account</p>
                <p className='text-base text-gray-600'>All your data and information will be deleted.</p>
                <div className='px-4 py-3 text-white bg-[#ff7f7e] w-max rounded-full '>Delete my account</div>
            </div>
        </div>
    )
}
export default Account
