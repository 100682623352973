import React from "react";
import { useState, useEffect } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import LoginButton from "../auth/loginButton";
import { useAuth } from "../auth/authContext";
import LogoutButton from "../auth/logout";
import open from "../../assets/menu-icon.svg";
import close from "../../assets/close-icon.svg";
import { useLocation, useNavigate } from "react-router";
import Searchbar from "../searchbar";
import searchIcon from "../../assets/Icons/search.svg";
import SearchMobileView from "./SearchMobileView";
import factacyIcon from "../../assets/Icons/Insights.svg"
import ChatWithGroot from "../chatWithGroot/ChatWithGroot";
import { useChat } from "../chatWithGroot/ChatContext";
import aiChat from "../../assets/ai-chat.png";

const FactacyIcon = () => (
  <svg
    className="sm:h-14 lg:h-20 "
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 579.04 183.32"
    style={{ enableBackground: "new 0 0 579.04 183.32" }}
    xmlSpace="preserve"
  >
    <style>
      {`
                .st0 { fill: #FFFFFF; }
                .st1 { fill: #2475BB; }
            `}
    </style>
    <g>
      <path
        className="st1"
        d="M317.37,66.88v12.25h-21.03v37.33h-14.02V79.12H261.2V66.88H317.37z"
      />
      <path
        className="st1"
        d="M480.2,66.88h16.08l-22.73,30.59v18.98h-14.02V97.47l-22.73-30.59h16.15l13.6,19.83L480.2,66.88z"
      />
      <rect x="82.77" y="66.88" className="st1" width="58.2" height="14.02" />
      <rect x="82.77" y="94.93" className="st1" width="29.92" height="14.02" />
      <polygon
        className="st1"
        points="199.96,116.46 185.01,116.46 166.51,81.19 147.98,116.46 133.05,116.46 133.13,116.3 159.12,66.89 
                173.92,66.89 	"
      />
      <polygon
        className="st1"
        points="377.53,116.46 362.57,116.46 344.07,81.19 325.54,116.46 310.62,116.46 310.7,116.3 336.69,66.89 
                351.48,66.89 	"
      />
      <g>
        <path
          className="st1"
          d="M232.07,105.64c-10.75,0-19.81-6.4-19.81-13.98c0-7.59,9.06-13.99,19.81-13.99c6.16,0,11.79,2.13,15.46,5.37
                    h0.02l8.66-8.68c-5.89-5.54-14.53-9.01-24.14-9.01c-17.72,0-32.11,11.79-32.11,26.3c0,14.49,14.4,26.28,32.11,26.28
                    c9.61,0,18.25-3.47,24.14-8.99l-8.68-8.68C243.87,103.53,238.24,105.64,232.07,105.64z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M409.64,105.64c-10.75,0-19.81-6.4-19.81-13.98c0-7.59,9.06-13.99,19.81-13.99c6.16,0,11.79,2.13,15.46,5.37
                    h0.02l8.66-8.68c-5.89-5.54-14.53-9.01-24.14-9.01c-17.72,0-32.11,11.79-32.11,26.3c0,14.49,14.4,26.28,32.11,26.28
                    c9.61,0,18.25-3.47,24.14-8.99l-8.68-8.68C421.43,103.53,415.8,105.64,409.64,105.64z"
        />
      </g>
    </g>
  </svg>
);

const IndividualSectionMobileView = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={`flex  p-4 rounded-xl w-full items-center justify-between transition-all ease duration-300 delay-100  ${isOpen ? "bg-blue" : "bg-gray-100"
          }`}
        onClick={() => setIsOpen((pre) => !pre)}
      >
        <p className="text-lg font-bold">{data.title}</p>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00372 8.78077L11.3036 5.48096L12.2464 6.42376L8.00372 10.6664L3.76108 6.42376L4.70389 5.48096L8.00372 8.78077Z"
            fill="black"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="p-2 flex flex-col gap-4 animate-navigationList">
          <div className="p-3 flex flex-col">
            {data.list.map((item, index) => (
              <a
                key={index}
                className="font-normal text-gray-800 pl-2 py-2 pr-10 hover:text-[#4a83c1] hover:bg-[#dce7f3] w-full"
                href={item.url}
                title={item.title}
              >
                {item.text}
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
const IndividualSectionDekstopView = ({ data }) => {
  return (
    <div className="p-3 flex flex-col">
      <p className="text-base leading-3 font-bold pl-2 py-2 pr-10">
        {data.title}
      </p>
      {data.list.map((item, index) => (
        <a
          key={index}
          className="font-normal text-gray-800 pl-2 py-2 pr-10 hover:text-[#4a83c1] hover:bg-[#dce7f3] w-full"
          href={item.url}
          title={item.title}
        >
          {item.text}
        </a>
      ))}
    </div>
  );
};

const Navbar = ({ isTopOfPage }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const search = {
    title: "Search",
    list: [
      {
        text: "Discover Company",
        title:
          "Search for Tearsheet information on startups, companies, vendors, clients and more",
        url: "/company-intelligence/company-search",
      },
      {
        text: "Discover People",
        title: "Search for person of interests, investors and inventors",
        url: "/people-intelligence/person-search",
      },
      {
        text: "Discover Investor",
        title:
          "Search for Investors, PE, VC, funds for their deals and investments",
        url: "/investor-intelligence/investor-search",
      },
      {
        text: "Discover Sector",
        title:
          "Search for Industrys, sectors for investment activity and innovations",
        url: "/sector-intelligence/sector-search",
      },
      {
        text: "Discover Patent",
        title: "Search for sector patents and innovations with keywords",
        url: "/patent-intelligence/patent-search",
      },
    ],
  };

  const deal = {
    title: "Deal Intelligence",
    list: [
      {
        text: "Deal Dashboard",
        url: "/deal-intelligence/deals-dashboard",
        title:
          "Interactive Dashboard for all the deals as per sector, geography, investors and more",
      },
      {
        text: "Latest Deals",
        url: "/deal-intelligence/deals",
        title: "Detailed list of all the recent and historical deals",
      },
      {
        text: "Historical Deals",
        url: "/deal-intelligence/historical-deals",
        title: "Detailed list of all the recent and historical deals",
      },
      // {
      //   text:  "Current and previous investors",
      //   url: "/under-construction",
      //   title: "Updates on latest and previous developments and deals of investors investing in companies- how much they have invested"
      // },
      {
        text: "Open deals",

        url: "/deal-intelligence/open-deals",
        title: "Announcements by Companies who plan to raise funds"
      },
      {
        text: "ALT Deals",
        url: "/deal-intelligence/alt-deals",
        title: "Competitors of a recent investee, who may be looking to raise funds"
      },
      // {
      //   text: "Exits",
      //   url: "/deal-intelligence/exits",
      //   title: "Deals with Exit information from previous investors",
      // },
      {
        text: "Investment trends and themes",
        url: "/deal-intelligence/investment-trends-themes",
        title: "Trends related to sectors, investors and investees",
      },
      {
        text: "Journal",
        url: "/deal-intelligence/reports/latest-every-day",
        title: "",
      },
    ],
  };

  const company = {
    title: "Company Intelligence",
    list: [
      {
        title:
          "Interactive Dashboard to view company data as per geographies, sectors, scale and more",
        text: "Company Dashboard",
        url: "/company-intelligence/company-dashboard",
      },
      {
        title:
          "Search for a company by name for profile, financials, key people, patents and news",
        url: "/company-intelligence/company-watch",
        text: "Company Watch",
      },
      {
        title:
          "User can Identify top local and global competitors of a specific company",
        url: "/company-intelligence/competitor-watch",
        text: "Competitor watch",
      },
      // {
      //   title: "Search for a companies by product or a service",
      //   url: "/under-construction",
      //   text: "Companies by Product or Service",
      // },
    ],
  };

  const investor = {
    title: "Investor Intelligence",
    list: [
      {
        text: "Investor Dashboard",
        title:
          "Interactive Dashboard to View Investor Activity across Geographies, Sectors, Companies and more",
        url: "/investor-intelligence/investor-dashboard",
      },
      // {
      //   text: "Investor Watch",
      //   title:
      //     "Search an investor for its profile details, portfolio, news and more",
      //   url: "/investor-intelligence/investor-watch",
      // },
      {
        text: "Sector wise Investor Activity",
        title:
          "Gain insights into investor strategies by exploring sector-wise investor activity",
        url: "/investor-intelligence/investor-activity-sectorwise",
      },
      // {
      //   text: "Geography wise Investor Activity",
      //   title: "Search for all the investment by a particular investor based on a geographical location",
      //   url: "/under-construction",
      // },
      {
        text: "Stage wise Investor Activity",
        title:
          "Monitor investments by investors based Early Stage, Growth Scale and more",
        url: "/investor-intelligence/investor-activity-stagewise",
      },
      {
        text: "Investor Leaderboard",
        title: "",
        url: "/investor-intelligence/investor-leaderboard",
      },
      {
        text: "Investment shifts and trends",
        title:
          "Track and analyse shifts and trends in the investment landscape",
        url: "/investor-intelligence/investment-shifts-trends",
      },
      {
        text: "ALT Portfolio",
        title:
          "Investment Opportunities for competing companies of particular investor",
        url: "/investor-intelligence/alt-portfolio",
      },
      {
        text: "Potential investors",
        title:
          "Investor Matchmaking with a list of investor based on investee portfolio",
        url: "/investor-intelligence/potential-investors",
      },
    ],
  };

  const sector = {
    title: "Sector Intelligence",
    list: [
      {
        title: "",
        url: "/sector-intelligence/sector-dashboard",
        text: "Sector Dashboard",
      },
      // {
      //   title: "",
      //   url: "/sector-intelligence/investors-smes",
      //   text: "Investors and SMEs",
      // },
      // {
      //   title: "",
      //   url: "/sector-intelligence/relevent-companies",
      //   text: "Relevant companies",
      // },
      // {
      //   title: "",
      //   url: "/under-construction",
      //   text: "Sector Clients and Vendors",
      // },
      // {
      //   title: "",
      //   url: "/sector-intelligence/sector-innovations",
      //   text: "Sector Innovations",
      // },
      // {
      //   title: "",
      //   url: "/under-construction",
      //   text: "Sector thesis and reports",
      // },
    ],
  };

  const innovations = {
    title: "Innovations",
    list: [
      {
        title: "",
        text: "Search Patents",
        url: "/patent-intelligence/patent-search",
      },
      {
        title: "",
        text: "Patent Dashboard",
        url: "/patent-intelligence/patent-dashboard",
      },
      // {
      //   title: "",
      //   text: "Patents by Industry",
      //   url: "/patent-intelligence/patent-search-by-industry",
      // },
      // {
      //   title: "",
      //   text: "Patents by Company",
      //   url: "/patent-intelligence/patent-search-by-company",
      // },
      // {
      //   title: "",
      //   text: "Patents by Investor",
      //   url: "/patent-intelligence/patent-search-by-investor",
      // },
    ],
  };

  const people = {
    title: "People Intelligence",
    list: [
      // {
      //   title: "",
      //   url: "/people-intelligence/cohorts-alumni-connections",
      //   text: "Cohorts, Alumni and connections",
      // },
      // {
      //   title: "",
      //   url: "/people-intelligence/contacts",
      //   text: "Contacts",
      // },
      // {
      //   title: "",
      //   url: "/people-intelligence/person-watch",
      //   text: "Person Watch",
      // },
      // {
      //   title: "",
      //   url: "/people-intelligence/inventors-innovators",
      //   text: "Inventors and Innovators",
      // },
      {
        title: "",
        url: "/people-intelligence/key-people",
        text: "Key People",
      },
      // {
      //   title: "",
      //   url: "/people-intelligence/send-outreach",
      //   text: "Send outreach",
      // },
    ],
  };

  const news = {
    title: "News Intelligence",
    list: [
      {
        title: "News Summaries for trending topics in the last 24 hours",
        text: "Hot Topics",
        url: "/news-intelligence/home-news",
      },
      {
        title: "Companies that are trending in the news",
        text: "Companies in news",
        url: "/news-intelligence/company-in-news",
      },
      {
        title:
          "Funding raised, investments done, mergers and acquisitions done",
        text: "Deals in news",
        url: "/news-intelligence/deals-news",
      },
      {
        title: "News related to the funding rounds, mergers & acquisitions",
        text: " Funding news",
        url: "/news-intelligence/funding",
      },
      // {
      //   title: "News related to the financial activities of investors, investment decisions, strategies",
      //   text: "Investor in news",
      //   url: "/under-construction",
      // },
      // {
      //   title: "People who are trending in a company or in news",
      //   text: "People in news",
      //   url: "/under-construction",
      // },
      {
        title: "Trending and upcoming sectors appearing in the news",
        text: "Sectors in news",
        url: "/news-intelligence/sectors",
      },
      {
        title: "News related to Environment, Social and Governance",
        text: "ESG news",
        url: "/news-intelligence/esg",
      },
      // {
      //   title: "Create your own real-time and summarized news feed",
      //   text: "My news feed",
      //   url: "/under-construction",
      // },
    ],
  };

  const community = {
    title: "Community Pages",
    list: [
      {
        text: "Leading Investors",
        title: "Investors ranked by their Deals and Deal Value",
        url: "/leaderboards/leading-investors",
      },
      {
        text: "Leading Sectors",
        title: "Hot Sectors being invested into",
        url: "/leaderboards/leading-sectors",
      },
      // {
      //   text: "Decacorns",
      //   title: "Companies with Valuations of $10 billion and above",
      //   url: "/under-construction",
      // },
      {
        text: "Unicorns",
        title: "Companies with Valuations of $1 billion and above",
        url: "/leaderboards/unicorns",
      },
      {
        text: "Soonicorns",
        title: "Companies near Unicorn status",
        url: "/leaderboards/soonicorns",
      },
      {
        text: "Shark Tank Featured",
        title: "Shark Tank Featured Companies",
        url: "/leaderboards/shark-tank-companies",
      },
    ],
  };

  const mobileViewList = [
    search,
    investor,
    deal,
    company,
    sector,
    people,
    innovations,
    news,
    community,
  ];
  const desktopViewList = [
    [search, deal],
    [company, investor],
    [sector, innovations],
    [people, news],
    [community],
  ];
  const { openChat } = useChat();
  const [isPopUpOn, setIsPopUpOn] = useState(false);
  const Navigate = useNavigate();
  const { user } = useAuth();
  const navbarBackground = isTopOfPage
    ? ""
    : "bg-gradient-to-b from-blue to-transparent";
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const MenuAnimationShow =
    isMenuToggled && "translateX" === 0
      ? "animate-hamburgerMenuHide"
      : "animate-hamburgerMenuShow";
  const MenuAnimationHide =
    isMenuToggled && "translateX" === 100
      ? "animate-hamburgerMenuShow"
      : "animate-hamburgerMenuHide";

  const NotSearhBarUrls = ["/", "/company-intelligence/company-search", "/people-intelligence/person-search", "/investor-intelligence/investor-search", "/sector-intelligence/sector-search", "/investor-intelligence/alt-portfolio"];
  const location = useLocation();

  const [updates, setUpdates] = useState(false);
  const updatesClick = () => {
    // const closestButton = event.target.closest("button");
    setUpdates(!updates);
  };

  const [isInViewport, setIsInViewport] = useState(true); // Assuming it's initially in the viewport

  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementById("myElement");
      if (element) {
        const rect = element.getBoundingClientRect();
        setIsInViewport(rect.left >= 0 && rect.right <= window.innerWidth);
      }
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      className={`${navbarBackground} font-roboto backdrop-blur z-50 w-full fixed top-0 h-max`}
    >
      <div className="flex items-center justify-between sm:mx-0 lg:pr-5 w-full mt-0 lg:h-max sm:h-12 font-roboto">
        {/* logo */}
        <div className="flex items-center lg:w-1/6 sm:w-1/2 h-auto m-0 pl-5">
          <a href="https://www.factacyinsights.com/">
            <img src={factacyIcon} className="min-w-full" />

          </a>
        </div>

        {isDesktop && !NotSearhBarUrls.includes(window.location.pathname) && (
          <div className="w-3/6 rounded-xl h-12">
            <Searchbar />
          </div>
        )}

        {/* discover and other things like factacy.ai and login logout button */}
        {isDesktop ? (
          <div className="items-center h-20 w-auto">
            <div className="flex justify-center items-center gap-12 text-sm h-full w-full">
              <div className="flex items-center h-20 cursor-pointer gap-1 group">
                <p className="font-semibold">Discover</p>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00372 8.78077L11.3036 5.48096L12.2464 6.42376L8.00372 10.6664L3.76108 6.42376L4.70389 5.48096L8.00372 8.78077Z"
                    fill="black"
                  />
                </svg>

                <div
                  id="myElement"
                  className={`top-full right-6 overflow-auto  ${isInViewport ? "w-max h-max" : "w-[80vw] h-[80vh]"
                    } absolute group-hover:flex hidden gap-2 p-3 bg-white rounded-md shadow-2xl flex-wrap`}
                >
                  {desktopViewList.map((item, index) => (
                    <div key={index}>
                      {item.map((data, index) => (
                        <IndividualSectionDekstopView key={index} data={data} />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="items-center w-max h-20 cursor-pointer gap-1 group relative group-hover:flex hidden"
                onMouseEnter={() => updatesClick(true)}
                onMouseLeave={() => updatesClick(false)}
              >
                <p className="font-semibold">Platforms</p>
                {updates && (
                  <div className="top-full left-0 right-6 overflow-auto h-max size-auto w-max absolute gap-4 p-3 bg-white rounded-md shadow-2xl flex flex-col">
                    <a
                      className="font-normal text-gray-800 pl-2 py-2 pr-10 hover:text-[#4a83c1] hover:bg-[#dce7f3] w-full"
                      href="https://www.startupinvestors.ai/"
                      target="_blank"
                      title="Curated list of India's most relevant startup investors"
                    >
                      Startupinvestor
                    </a>
                    <a
                      className="font-normal text-gray-800 pl-2 py-2 pr-10 hover:text-[#4a83c1] hover:bg-[#dce7f3] w-full"
                      href="https://www.aicite.ai/"
                      target="_blank"
                      title="News Summaries for trending topics in the last 24 hours"
                    >
                      Aicite
                    </a>
                  </div>
                )}
              </div>

              <a className="flex items-center w-max h-20 cursor-pointer" href="/home/my-feed"><p className="font-semibold">My Feed</p></a>

              {user.isAuthenticated == true ? (
                <LogoutButton />
              ) : (
                <LoginButton />
              )}
            </div>
          </div>
        ) : (
          <div className="flex gap-3 pr-4">
            <img
            className={`w-6 cursor-pointer `}
            src={aiChat}
            alt="searchIcon"
            onClick={() => openChat()}
          />
            <img
              src={searchIcon}
              alt="searchIcon"
              onClick={() => setIsPopUpOn(true)}
              className=" cursor-pointer"
            />
            <img
              alt="menu-icon"
              src={open}
              className="cursor-pointer"
              onClick={() => setIsMenuToggled((pre) => !pre)}
            />
          </div>
        )}

        {!isDesktop && isMenuToggled && (
          <div
            className={`fixed right-0 top-0 left-0 bottom-0 h-screen w-screen ${MenuAnimationShow} ${MenuAnimationHide} ${isMenuToggled ? "overflow-auto" : "overflow-hidden"
              } `}
          >
            <div className="flex flex-col h-max bg-white backdrop-blur pr-4 gap-8 text-sm font-semibold">
              <div className="flex justify-between h-full">
                <div className="flex w-auto h-auto m-0 py-0">
                  <a href="https://www.factacyinsights.com/">
                    {/* <FactacyIcon /> */}
                    <img src={factacyIcon} className="w-56 p-3" />
                  </a>
                </div>
                <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                  <img className="" src={close} alt="close" />
                </button>
              </div>

              <div className="flex flex-col justify-between h-full pb-4">
                <div className="p-6 gap-3 flex flex-col">
                  <div className="flex justify-end">
                    {user.isAuthenticated == true ? (
                      <LogoutButton />
                    ) : (
                      <LoginButton />
                    )}
                  </div>
                  {mobileViewList.map((data, index) => (
                    <IndividualSectionMobileView key={index} data={data} />
                  ))}
                  <div className="p-4 rounded-xl bg-gray-100">
                    <p className="text-lg font-bold">About us</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <SearchMobileView isPopUpOn={isPopUpOn} setIsPopUpOn={setIsPopUpOn} />

      {/* <ChatWithGroot /> */}
    </nav>
  );
};
export default Navbar;